import React, {useState, useEffect} from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios';
import moment from 'moment/moment';
import LoadingIcon from '../components/LoadingIcon';
import CreateReturn from '../components/CreateReturn';
import OpenReturn from '../components/OpenReturn';

function Return() {

  const [ data, setData ] = useState([]);
  
  const [ filtered, setFiltered ] = useState([]);
  const [ search, setSearch ] = useState("");
  const [ createInvoice, setCreateInvoice ] = useState(false);

  const [ loading, setLoading ] = useState(true);

  const [ loadedInvoice, setLoadedInvoice ] = useState(false);

  const fetchData = async (e) => {

    const res = await axios.get("https://backend.azupin.glass/return");

    setData(res["data"])

    filter(res["data"])
    setLoading(false)

  };

  const filter = (e = data) => {
    const filtrd = e.filter(x => 
      x["customer"].toLowerCase().includes(search.toLowerCase())
      ||
      x["PO"].toLowerCase().includes(search.toLowerCase())
      ||
      x["order"].toLowerCase().includes(search.toLowerCase())
      ||
      moment(x["timestamp"]).format("L").toLowerCase().includes(search.toLowerCase())
    );

    setFiltered(filtrd);
  }

  const getTotal = (a) => {

    let sum = 0;
    
    a.forEach(x => sum += x["salePrice"])

    return sum.toFixed(2);
  }

  useEffect(() => {

    fetchData();

    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    filter();

  }, [search, setSearch])

  return (
    <div className="lg:flex">

      <Navbar />

      {
        createInvoice
        ?
        <CreateReturn fetchData={fetchData} setCreateInvoice={setCreateInvoice} />
        :
        ""
      }

      {
        loadedInvoice
        ?
        <OpenReturn fetchData={fetchData} loadedInvoice={loadedInvoice} setLoadedInvoice={setLoadedInvoice} />
        :
        ""
      }

      <div className="lg:overflow-y-scroll lg:h-screen w-full bg-gray-100">
        {
          loading
          ?
          <LoadingIcon />
          :
          <div className={`mx-auto`}>
            <div className="flex justify-between items-center px-10 py-4 bg-[#252E3E]">
              <h3 className="font-bold text-lg text-white">
                Returns!
              </h3>
              <button onClick={() => setCreateInvoice(true)} className="px-4 py-2 font-bold bg-white rounded shadow text-black hover:bg-gray-600 duration-300">Create</button>
            </div>
            <div className="w-full p-4 flex lg:flex-row flex-col lg:justify-start lg:items-center gap-4">
              <div className="p-4 bg-white border-b border-b-blue-700 flex-1 rounded shadow-lg">
                <h3 className="uppercase text-gray-400 font-bold mb-1">Total Orders</h3>
                <div className="flex justify-start items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                  </svg>
                  <p className="text-4xl font-bold">{data.length}</p>
                </div>
              </div>
              <div className="p-4 bg-white border-b border-b-blue-700 flex-1 rounded shadow-lg">
                <h3 className="uppercase text-gray-400 font-bold mb-1">Total Revenue</h3>
                <div className="flex justify-start items-center">
                  <p className="text-4xl font-bold">${getTotal(data)}</p>
                </div>
              </div>
              <div className="p-4 bg-white border-b border-b-blue-700 flex-1 rounded shadow-lg">
                <h3 className="uppercase text-gray-400 font-bold mb-1">Today</h3>
                <div className="flex justify-start items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                  </svg>
                  <p className="text-4xl font-bold">{moment().format("L")}</p>
                </div>
              </div>
              <div className="p-4 bg-white border-b border-b-blue-700 flex-1 rounded shadow-lg">
                <h3 className="uppercase text-gray-400 font-bold mb-1">Total  Invoices Unpaid</h3>
                <div className="flex justify-start items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                  </svg>
                  <p className="text-4xl font-bold">{data.filter(a =>  Number(Number(Number(a["salePrice"].toFixed(2)) + Number(a["tax"].toFixed(2))).toFixed(2) - Number(Number(a["deposit"]).toFixed(2))).toFixed(2) >= 0.1).length }</p>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-start items-start">
              <div className="flex flex-col w-full">
                <div className="overflow-x-auto">
                  <div className="py-2 inline-block min-w-full">
                    <div className="overflow-hidden">
                      <table className="min-w-full">
                        <thead className="bg-gray-50 border-b">
                          <tr>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              Date
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              #
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              Order #
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left flex justify-center items-center">
                              <input onChange={(e) => setSearch(e.target.value)} placeholder="Search..." className="w-full px-2 py-1 border outline-none" />
                            </th>
                            {/* <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              Status
                            </th> */}
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              Items
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            filtered.map((x, index) => (
                              <tr onClick={() => setLoadedInvoice(x["_id"])} key={x["_id"]} className={`hover:bg-blue-200 cursor-pointer duration-300 border-b ${!Boolean(index % 2) ? "bg-gray-100" : "bg-white"}`}>
                                <td className="px-6 w-28 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                  {moment(x["timestamp"]).format("L")}
                                </td> 
                                <td className="px-6 w-28 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                  {x["order"]}
                                </td>
                                <td className="px-6 w-28 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                  {x["cost"]}
                                </td>
                                <td className="text-sm text-gray-900 py-4 px-6 whitespace-nowrap">
                                  <div>
                                    <h3 className="font-bold">
                                      {x["customer"]}
                                    </h3>
                                    <p className="text-xs truncate w-96">
                                      {x["message"]}
                                    </p>
                                  </div>
                                </td>
                                {/* <td className="text-lg text-gray-900 px-6 py-4 whitespace-nowrap justify-center">
                                  {
                                    x["deposit"]
                                    ?
                                    <p className="text-xs">
                                      Deposit: ${x["deposit"]}
                                    </p>
                                    :
                                    ""
                                  }
                                  <h3 className="font-bold">
                                    {Number(Number(Number(x["salePrice"].toFixed(2)) + Number(x["tax"].toFixed(2))).toFixed(2) - Number(Number(x["deposit"]).toFixed(2))).toFixed(2) <= 0.1 ? "PAID" : `$${Number(Number(Number(x["salePrice"].toFixed(2)) + Number(x["tax"].toFixed(2))).toFixed(2) - Number(Number(x["deposit"]).toFixed(2)) ).toFixed(2)} DUE`}
                                  </h3>
                                </td> */}
                                <td className="text-lg text-grey-900 py-4 px-6 whitespace-nowrap justify-center">
                                  <h3 className="font-bold">
                                  {
                                      x["products"].filter(a => a["dispatched"] === "dispatched" || a["dispatched"] === true).length + " / " + x["products"].length
                                    }
                                  </h3>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        }
      </div>
    </div>
  )
}

export default Return