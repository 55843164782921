import axios from 'axios'
import { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar';
import BarcodeReader from 'react-barcode-reader'
import LoadingIcon from '../components/LoadingIcon';
import moment from 'moment';
import CheckInvoice from '../components/CheckInvoice';
import watermark from 'watermarkjs';
import logoPNG from './logo.png';
import { saveAs } from 'file-saver';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Legend
} from "chart.js";

function MainPage() {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Legend
  )

  const [ data, setData ] = useState([]);
  const [ asd, setURL] = useState("");
  const [ loading, setLoading ] = useState(true);
  const [ scanLoading, setScanLoading ] = useState(false);
  const [ scanned, setScanned ] = useState();
  const [ loadedInvoice, setLoadedInvoice ] = useState(false);

  const logoMarkImage = (file, type) => {

    watermark([file, logoPNG])
      // .dataUrl(watermark.image.lowerRight(0.5))
      // .load([logoPNG])
      // .dataUrl(watermark.image.lowerLeft(0.5))
      // .load([logoPNG])
      // .dataUrl(watermark.image.upperLeft(0.5))
      // .load([logoPNG])
      // .dataUrl(watermark.image.upperRight(0.5))
      // .load([logoPNG])
      // .dataUrl(watermark.image.center())
      // .dataUrl((coffee, logo) => {

      //   let context = coffee.getContext("2d");
      //   context.save();

      //   context.globalAlpha = 0.2;
      //   context.drawImage(logo, 100, 100);

      //   context.restore();

      //   return coffee

      // })
      .then(function (url) {
        saveAs(url)
      });

  }
  

  const getFiles = (e) => {

    e.forEach(x => {

      logoMarkImage(x["base64"], x["type"])
    })
    // console.log(e[0])
    setData({...data, ...e})
  } 

  const formatDates = (da) => {

    let dates = [];

    const CURRENT_MONTH = moment().month(); // int

    const arr = da.filter(e => moment(e["timestamp"]).year() === moment().year())

    // POPULATE ARRAY;

    for(let i = 0; i <= CURRENT_MONTH ; i++){
      dates.push({month: i, count: 0});
    };

    arr.forEach(x => {
      const int = Number(new Date(x["timestamp"]).getMonth())
      dates[int]["count"] += x["salePrice"]
    })

    return dates;
  }

  const callbac = async(a) => {
    if(a.length === 4){
      setLoadedInvoice(a);
    }else{

      const res = await axios.get("https://backend.azupin.glass/product/" + a);

      setScanned(res["data"]);

    }
  }
  
  function organizeOrdersByDay(orders = data["orders"]) {
    // Create an array of 31 elements, all initialized to 0
    // This covers all days in any month (1-31)
    const ordersByDay = new Array(moment().daysInMonth()).fill(0);

    orders.forEach(order => {
        // Extract the day from the timestamp
        const day = new Date(order.timestamp).getDate();

        // Increment the count for this day
        // Subtract 1 from the day to match the array index (0-based)
        ordersByDay[day - 1]++;
    });

    return ordersByDay;
  }

  function getDaysArrayByMonth() {
    var daysInMonth = moment().daysInMonth();
    var arrDays = [];
  
    while(daysInMonth) {
      var current = moment().date(daysInMonth);
      arrDays.push(String(moment(current).format('ddd D')))
      daysInMonth--;
    }
  
    return arrDays.reverse();
  }

  const fetchData = async (e) => {

    const res = await axios.get("https://backend.azupin.glass/monthly-report");

    setData(res["data"])

    setLoading(false)

  };

  useEffect(() => {

    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = "Bearer " + token;
      
      return config;
    });

    // setLoading(false)
    fetchData();


    // eslint-disable-next-line
  }, [])

  return (
    <div className="lg:flex">

      <Navbar />

      {
        !loadedInvoice
        ?
        <BarcodeReader
          onError={callbac}
          onScan={callbac}
        />
        :
        ""
      }

      {
        loadedInvoice
        ?
        <CheckInvoice fetchData={() => {}} loadedInvoice={loadedInvoice} setLoadedInvoice={setLoadedInvoice} />
        :
        ""
      }


      <div className="w-full h-screen overflow-y-scroll bg-gray-100">
        {
          loading
          ?
          <LoadingIcon />
          :
          <div className=" max-w-screen-2xl mx-auto px-4 mt-10">

            <div className="flex justify-start items-center gap-4 h-72">
              {/* this month's sales total qty */}
              <div className="flex flex-col shadow border h-full bg-white w-96 justify-center items-center ">
                <div className="-z-0 flex flex-col text-center justify-center items-center">
                  <h4 className="text-2xl font-semibold">
                    This Month's Sales
                  </h4>
                  <p className="text-4xl">
                    {data["Msales"]}
                  </p>
                </div>
              </div>
              {/* this month's sale sum total */}
              <div className="flex flex-col shadow border h-full bg-white w-96 justify-center items-center ">
                <div className="-z-0 flex flex-col text-center justify-center items-center">
                  <h4 className="text-2xl font-semibold">
                    This Month's Revenue
                  </h4>
                  <p className="text-4xl">
                    ${data["Mrev"]}
                  </p>
                </div>
              </div>
              {/* this month's new customers */}
              <div className="flex flex-col shadow border h-full bg-white w-96 justify-center items-center ">
                <div className="-z-0 flex flex-col text-center justify-center items-center">
                  <h4 className="text-2xl font-semibold">
                    Month's New Customers
                  </h4>
                  <p className="text-4xl">
                    {data["Mcustomers"]}
                  </p>
                </div>
              </div>
              {/* best customer this month */}
              <div className="flex flex-col shadow border h-full bg-white w-96 justify-center items-center ">
                <div className="-z-0 flex flex-col text-center justify-center items-center">
                  <h4 className="text-2xl font-semibold">
                    Month's Best Customer
                  </h4>
                  <p className="text-xl">
                    <span className="font-bold">{data["bestCustomer"]}</span> : ${data["customerSpent"]}
                  </p>
                </div>
              </div>
            </div>
            
            <div className="bg-white shadow my-4 p-4">
              <Line
                options={
                  {
                    animation: {
                      easing: "easeInOutBack"
                    },
                    scales: {
                      y: {
                        grid: {
                          display: false
                        }
                      },
                      x: {
                        grid: {
                          display: false
                        }
                      }
                    }
                  }
                }
                data={
                  {
                    labels: getDaysArrayByMonth(),
                    datasets: [{
                      label: 'Orders this Month',
                      data: organizeOrdersByDay(),
                      backgroundColor: 'lightgrey',
                      borderColor: 'grey',
                      fill: true,
                      borderWidth: 1
                    }]
                  }
                }
              />
            </div>


              {/* {
                  scanned
                  ?
                    scanLoading
                    ?
                    <LoadingIcon />
                    :
                    <div className="w-full h-[24rem] flex justify-center items-center flex-col">
                      <div className="flex flex-col">
                        <p className="text-xs leading-[.5rem]">{scanned["barcode"]}</p>
                        <h3 className="text-2xl font-bold">{scanned["title"]}</h3>
                      </div>
                      <div className="gap-4 flex justify-start items-center flex-row mt-4">
                        <div className="p-4 shadow border flex flex-col justify-center items-center w-40 h-40 select-none">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                          </svg>
                          <p className="font-semibold text-4xl">{scanned["stock"] ? scanned["stock"] : 0}</p>
                        </div>
                        <div className="p-4 shadow border flex flex-col justify-center items-center w-auto h-40 select-none">
                          <img src={`https://barcode.tec-it.com/barcode.ashx?data=${scanned["barcode"]}&translate-esc=on`} className="h-full" />
                        </div>
                        <div className="p-4 shadow border flex flex-col justify-center items-center w-auto h-40 select-none">
                          <img src={scanned["image"]} className="h-full" />
                        </div>
                      </div>
                      <div className="flex justify-center items-center mt-4">
                        <div className="border p-2">
                          <h3 className="font-light text-2xl">Located in isle: {scanned["site"]}</h3>
                        </div>
                      </div>
                    </div>
                  :
                  <div className="flex justify-center items-center h-full flex-row scale-150 animate-pulse">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                    </svg>
                    <h4>Scan to get started</h4>
                  </div>
              } */}
            </div>
        }
      </div>
    </div>
  );
}

export default MainPage;
