import React, { useState, useEffect } from 'react'
import ClickAwayListener from 'react-click-away-listener';

function KobiSelect({ currentValue, callback, index, products }) {

  const [ onField, setOnField ] = useState(false);
  const [ data, setData ] = useState();
  const [ text, setText ] = useState(currentValue);
  const [ copy, setCopy ] = useState([]);

  const clickAway = () => {
    console.log("Clicked Away")
    setTimeout(() => {
      setOnField(false)
    }, 150);
  }

  const search = () => {

    try{
      const fileredArray = products.filter(x => (
        x["title"]?.toLowerCase().includes(text.toLowerCase())
        ||
        x["barcode"]?.toLowerCase().includes(text.toLowerCase())
      ))
  
      setCopy(fileredArray);

    }catch(err){

      console.log(err)
    }

  }

  useEffect(() => {

    search()

  }, [text, setText])

  return (
    <ClickAwayListener onClickAway={clickAway}>
      <div className="block">
        {
          text
          ?
          <input value={text} onChange={(e) => setText(e.target.value)} onClick={() => setOnField(true)} className="overflow-x-hidden text-xs w-[30rem] border px-4 py-3 outline-none rounded" placeholder="Search..." />
          :
          <input value={text} onChange={(e) => setText(e.target.value)} onClick={() => setOnField(true)} className="overflow-x-hidden text-xs w-[30rem] border px-4 py-3 outline-none rounded" placeholder="Search..." />
        }
        {
          text && onField
          ?
          <div className="max-h-96 h-auto overflow-y-scroll flex flex-col static z-[100] overflow-x-hidden -bottom- border lg:w-[30rem] shadow bg-white">
            {
              copy.map((x, d) => (
                <div onClick={(e) => {e.stopPropagation(); setData(x) ;callback(x["_id"], index); setOnField(false); setText(x["title"])}} key={d} className="flex flex-row justify-between items-center p-2 hover:bg-gray-100 duration-150 cursor-pointer">
                  <div className="flex flex-col">
                    <p className="text-[0.5rem] leading-[0.4rem]">{x["key"]}</p>
                    <h4 className="text-xs">{x["title"]}</h4>
                  </div>
                  <img className="w-10 h-10" src={x["image"]} />
                </div>
              ))
            }
          </div>
          :
          ""
        }
      </div>
    </ClickAwayListener>
  )
}

export default KobiSelect