import React, {useState, useEffect} from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios';
import moment from 'moment/moment';
import OpenGlass from '../components/OpenGlass';
import { Fade } from 'react-awesome-reveal'
import EachInventory from '../components/EachInventory';
import CreateInventory from '../components/CreateInventory';
import BarcodeReader from 'react-barcode-reader'
import AddInventory from '../components/AddInventory';
import LoadingIcon from '../components/LoadingIcon';
import CreateGroup from '../components/CreateGroup';

function Inventory() {

  const [ data, setData ] = useState([]);
  const [ groups, setGroups ] = useState([]);
  const [ createGroup, setCreateGroup ] = useState(false);

  const [ filtered, setFiltered ] = useState([]);
  const [ search, setSearch ] = useState("");
  const [ searchType, setSearchType ] = useState("");

  const [ loading, setLoading ] = useState(true);
  const [ loadingEdit, setLoadingEdit ] = useState(true);
  const [ create, setCreate ] = useState(false);
  const [ add, setAdd] = useState(false);

  const [ editing, setEditing ] = useState();

  const fetchData = async (e) => {

    const res = await axios.get("https://backend.azupin.glass/product");
    const groupsRes = await axios.get("https://backend.azupin.glass/groups");

    setData(res["data"].sort((a, b) => a.title?.localeCompare(b.title)))
    setGroups(groupsRes["data"]);

    filter(res["data"])
    setLoading(false)

  };

  const filter = (e = data) => {

    console.log("HI")
    let filtrd; 
    
    filtrd = e.filter(x => 
      x["title"]?.toLowerCase().includes(search.toLowerCase())
      ||
      x["key"]?.toLowerCase().includes(search.toLowerCase())
      ||
      x["barcode"]?.toLowerCase().includes(search.toLowerCase())
    );

    // filtrd = filtrd.filter(x => 
    //   x["type"]?.toLowerCase().includes(searchType.toLowerCase())
    // );

    setFiltered(filtrd);
  }

  useEffect(() => {

    fetchData();

    // window.document.addEventListener("keydown", (e) => {
    //   console.log(e)
    // })

    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    filter();

  }, [search, setSearch, searchType, setSearchType])

  const callbac = (a) => {
    setSearch(a)
  }

  return (
    <div className="lg:flex">

      {
        createGroup
        ?
        <CreateGroup fetchData={fetchData} createInvoice={createGroup} setCreateInvoice={setCreateGroup} />
        :
        ""
      }
      <Navbar />
      {
        add

        ?
        ""
        :
        <BarcodeReader
          onError={callbac}
          onScan={callbac}
          />
      }
      <div className="overflow-y-scroll h-screen w-full">
        {
          loading
          ?
          <LoadingIcon />
          :
          <div className={`mx-auto`}>
            <div className="flex flex-row justify-start items-start">
              <div className="flex flex-col w-full">
                <div className="overflow-x-auto">

                  <div className="p-16">



                    <div className="mb-12 flex justify-between items-center">
                      <h3 className="font-bold text-2xl">Inventory</h3>
                      <div className="flex justify-center items-center gap-4">
                        {/* <button onClick={() => {setAdd(!add); create ? setCreate(false) : console.log("A")}} className="px-4 py-2 bg-blue-400 font-bold text-white rounded shadow transform hover:scale-105 duration-300">Add</button> */}
                        <button onClick={() => {setCreate(!create); add ? setAdd(false) : console.log("B")}} className="px-4 py-2 bg-blue-400 font-bold text-white rounded shadow transform hover:scale-105 duration-300">Create</button>
                        <button onClick={() => setCreateGroup(true)} className="px-4 py-2 bg-blue-400 font-bold text-white rounded shadow transform hover:scale-105 duration-300">Create Group</button>
                      </div>
                    </div> 

                    {
                      create && !add
                      ?
                      <CreateInventory fetchData={fetchData} />
                      :
                      ""
                    }

                    {
                      add
                      ?
                      <AddInventory fetchData={fetchData} />
                      :
                      ""
                    }

                    
                    {/* Online Stuff */}

                    {/* <div className="border-b grid lg:grid-cols-8 text-gray-400 font-semibold text-xs px-2 pb-2 mb-2 justify-center items-center">
                      <div>
                        #
                      </div>
                      <div className="col-span-4">
                        <input placeholder="Search" className="outline-none border-b border-gray-500 text-black focus:border-blue-700 duration-300 py-1 w-60" onChange={(e) => setSearch(e.target.value)} value={search} />
                      </div>
                      <div>
                      </div>
                      <div>
                        SKU
                      </div>
                      <div>
                        Price
                      </div>
                    </div> */}

                    {/* <div className="max-h-96 overflow-y-scroll border shadow">
                      {
                        groups.map(x => (
                          <div className={` grid lg:grid-cols-8 col-span-8 cursor-pointer justify-center py-2`}>
                            <div className={`flex justify-start items-center flex-row pl-2 `}>
                              {x["image"] ? 
                              <img loading="lazy" src={x["image"]} className={`w-10 h-10 rounded overflow-hidden `} /> 
                              : 
                              <div className="w-10 h-10 bg-blue-100 rounded"></div>
                              }
                            </div>
                            <div className="col-span-4 ">
                              <div className="flex flex-col">
                                <h3>
                                {x["title"]}
                                </h3>
                              </div>
                            </div>
                            <div className="flex justify-start items-center ">
                              {x["type"]}
                            </div>
                            <div className="flex justify-start items-center pr-2 ">
                              {x["key"]}
                            </div>
                            <div className="flex justify-start items-center pr-2 ">
                                ${x["price"] ? x["price"] : "0"}
                            </div>
                          </div>
                        ))
                      }
                    </div> */}

                    <div className="mt-10 border-b grid grid-cols-8 text-gray-400 font-semibold text-xs px-2 pb-2 mb-2 justify-center items-center">
                      <div className="lg:block hidden">
                        #
                      </div>
                      <div className="col-span-8 lg:col-span-4">
                        <input placeholder="Search" className="outline-none border-b border-gray-500 text-black focus:border-blue-700 duration-300 py-1 w-full lg:w-60" onChange={(e) => setSearch(e.target.value)} value={search} />
                      </div>
                      <div className="lg:block hidden">
                       <input placeholder="Group" className="outline-none border-b border-gray-500 text-black focus:border-blue-700 duration-300 py-1 w-auto" onChange={(e) => setSearchType(e.target.value)} value={searchType} />
                      </div>
                      <div className="lg:block hidden">
                        Stock
                      </div>
                      <div className="lg:block hidden">
                        Price
                      </div>
                    </div>


                    {
                      filtered.map((x, index) => (
                        <EachInventory fetchData={fetchData} key={x["_id"]} x={x} />
                      ))
                    }

                  </div>                  

                </div>
              </div>
            </div>
          </div>

        }
      </div>
    </div>
  )
}

export default Inventory