import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios';
import Textarea from 'react-expanding-textarea'
import OpenProduct from './OpenProduct';
import Select from 'react-select'
import moment from 'moment';
import KobiSelect from './KobiSelect';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import DateTimePicker from 'react-datetime-picker';

function OpenEstimate({fetchData, loadedInvoice, setLoadedInvoice}) { 

  const textareaRef = useRef(null);
  const [ createProduct, setCreateProduct ] = useState(false);
  const [ active, setActive ] = useState(true);
  const [ data, setData ] = useState({
        // customer: "Robles Glass Supplies Inc",
        // billingAddress: "1515 N Kraemer Blvd Ste M, Anaheim, CA 92806",

        // user: "Kobi",
        // order: "6149",
        // PO: "Placentia Loreto",
        // products: [
        //   {
        //     "_id": "SOME-ID-NUMBER",
        //     "title": `AZ-DS01 60"W X 66"H in Chrome Finish`,
        //     "description": `Frameless Double Sliding Shower Door with Hardware Kit. \n\n Type: Double Towel Bars \n Thickness: 3/8" Clear Tempered \n Finish: Chrome \n Size: 60"x66"`,
        //     "quantity": 1,
        //     "rate": 362.4,
        //     "tax": false,
        //   }
        // ],

        handler: localStorage.getItem("username"),

        // terms: "COD",

        // tax: 0,

        // deposit: 0,

        // message: "PO: Placentia Loreto",
        // cost: 240,
        // salePrice: 362.4,
  });

  const [ product, setProduct ] = useState([]);

  const [ loading, setLoading ] = useState(true);

  const fetchInvoice = async () => {

    const res = await axios.get("https://backend.azupin.glass/estimate/" + loadedInvoice);
    setData(res["data"])
    setLoading(false)
  }

  const getTotal = () => {
    let total = 0;
    data["products"].forEach(x => {
      total += x["quantity"] * x["rate"]
    })

    return total
  }

  const save = async () => {

    setLoading(true)

    const res = await axios.post("https://backend.azupin.glass/estimate/edit", {...data, salePrice: data["products"].length === 1 ? Number(data["products"][0]["quantity"] * data["products"][0]["rate"]).toFixed(2) : getTotal()});

    
    if(res["data"]["message"] === "success"){
      setTimeout(() => {
        setLoading(false);
        fetchData();
        setActive(false); 
        setTimeout(() => {setLoadedInvoice(false)}, 500)
      }, 1000);
    }
  }


  const editQuantity = (val, index) => {
    let copy = data;

    copy["products"][index]["quantity"] = Number(val);

    setData({...data, products: copy["products"]})

  }

  const changeDescription = (val, index) => {
    let copy = data;

    copy["products"][index]["description"] = val;
    
    setData({...data, products: copy["products"]})
  }

  const changeProduct = (val, index) => {
    let copy = data;

    copy["products"][index]["title"] = val;

    setData({...data, products: copy["products"]});
  }

  const changeRate = (val, index) => {
    let copy = data;

    copy["products"][index]["rate"] = Number(val);

    setData({...data, products: copy["products"]});
  }

  const deleteRow = (index) => {

    setData({...data, products: data["products"].filter((x, i) => i !== index)})   

  }

  const fetchProducts = async () => {
    const res = await axios.get("https://backend.azupin.glass/product");

    setProduct(res["data"])
    console.log("Pressed")
    
  }

  const deleteInvoice = async () => {

    if(window.confirm("Are you sure you want to delete this invoice?")){
      const res = await axios.delete("https://backend.azupin.glass/estimate/" + data["_id"]);

      if(res){
        return window.location.reload();
      }
    }

  }

  const convertToInvoice = async () => {


    if(window.confirm("Are you sure you want to convert this into an invoice?")){
      // send data to invoice srvr to create
      const res = await axios.post("https://backend.azupin.glass/invoice/", {...data, orderDate: String(new Date()), salePrice: data["products"].length === 1 ? Number(data["products"][0]["quantity"] * data["products"][0]["rate"]).toFixed(2) : getTotal(), tax: data["tax"] >= 1 ? Number((9.5 / 100) * getTotal()).toFixed(2) : 0.00});
      if(res){

        const ESTIMATE_REQUEST = await axios.post("https://backend.azupin.glass/estimate/edit", {...data, id: data["_id"], tax: data["tax"] >= 1 ? Number((9.5 / 100) * getTotal()).toFixed(2) : 0.00, salePrice: data["products"].length === 1 ? Number(data["products"][0]["quantity"] * data["products"][0]["rate"]).toFixed(2) : getTotal(), converted: true});
    
        if(ESTIMATE_REQUEST["data"]["message"] === "success"){
          setTimeout(() => {
            setLoading(false);
            fetchData();
          }, 1000);

          return window.location.href = "/invoice"
        
        }

      }


    }


  }
  const changeThisProduct = (val, index, field) => {
    let copy = data;

    copy["products"][index][field] = val;
    
    setData({...data, products: copy["products"]})
  }
  const handleChangeProduct = async (e, index) => {

    const dta = product.find(x => x["_id"] === e);

    console.log(dta)

    changeDescription(dta["description"], index);
    changeProduct(dta["title"], index);
    changeRate(dta[data["customerType"] ? data["customerType"] : "contractorPricing"] ? dta[data["customerType"] ? data["customerType"] : "contractorPricing"] : 0, index);
    changeThisProduct(dta["contractorPricing"], index, "contractorPricing");
    changeThisProduct(dta["installerPricing"], index, "installerPricing");
    changeThisProduct(dta["shopPricing"], index, "shopPricing");
    changeThisProduct(dta["barcode"], index, "barcode");
    changeThisProduct(dta["stock"], index, "stock");

  }

  const addProduct = () => {
    setData({...data, products: [...data["products"], {
        _id: "", 
        title: "", 
        description: "", 
        quantity: 1, 
        rate: 1, 
        tax: false
      }]
    })
  }

  useEffect(() => {
    fetchInvoice();

    fetchProducts();
  }, [])

  return (
    <div className={`${active ? "slide-in-top" : "slide-out-top"} fixed top-0 left-0`}>
      {
        createProduct
        ?
        <OpenProduct fetchProducts={fetchProducts} setCreateProduct={setCreateProduct} />
        :
        ""
      }
      <div className='bg-white relative flex justify-center items-center w-screen overflow-y-scroll h-screen top-0 left-0'>
        <div className="flex justify-between items-center absolute top-0 left-0 py-2 px-4 w-full">
          <div className="flex justify-start items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
            <h3 className="font-bold text-xl">
              Estimate #{data["order"]}
            </h3>
          </div>
          <svg onClick={() => {setActive(false); setTimeout(() => {setLoadedInvoice(false)}, 500)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-10 h-10 hover:text-red-500 duration-300">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        {
          loading
          ?
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 animate-spin">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
          :
          <div className="w-full h-full">
            <div className="px-4 pt-14 pb-4 bg-gray-100 flex flex-row justify-between">
              <div className="flex flex-row justify-start items-start">

                <div className="flex flex-col justify-start items-start mr-4">
                  <label for="customer">Customer:</label>
                  <div className="w-full flex justify-start items-center bg-white border p-2">
                    {data["customer"]}
                  </div>
                  <label for="billingAddress">Billing Address:</label>
                  <textarea onChange={(e) => setData({...data, billingAddress: e.target.value})} value={data["billingAddress"]} id="billingAddress" name="billingAddress" className="px-4 py-2 border w-full">
                  </textarea>
                  <label for="quoteDate">Quote Date</label>
                  <DateTimePicker className="bg-white border px-4 py-[.4rem] mb-2 outline-none" onChange={(e) => {setData({...data, quoteDate: e})}} value={data["quoteDate"]} />

                </div>
                <div className="flex flex-col justify-start items-start w-72">
                  <div className="flex flex-col justify-start items-start">
                    <label for="PO">P.O</label>
                    <input name="PO" id="PO" onChange={(e) => setData({...data, PO: e.target.value})} value={data["PO"]} className="bg-white border px-4 py-[.4rem] outline-none" />
                  </div>
                  <label for="billingAddress">Shipping Address:</label>
                  <textarea onChange={(e) => setData({...data, shippingAddress: e.target.value})} value={data["shippingAddress"]} id="shippingAddress" name="billingAddress" className="px-4 py-2 border w-full">
                  </textarea>
                </div>
              </div>
              <div>
                <h3>Useful Details</h3>
                <div className="bg-white px-4 py-2">
                  <span className="font-bold">BALANCE</span> ${data["balance"] ? data["balance"] : "0"}
                </div>
                <div className="bg-white px-4 py-2">
                  <span className="font-bold">RESALE</span> {data["taxException"] ? "YES" : "NO"}
                </div>

                <div className="flex flex-col justify-start items-start mt-4">

                  <label for="shipment">Shipment</label>
                  <input value={data["shipment"]} onChange={(e) => setData({...data, shipment: e.target.value})} className="bg-white border px-4 py-[.4rem] mb-2 outline-none" />

                </div>
              </div>
              <div>
                <h3 className="uppercase text-xs text-right">BALANCE DUE</h3>
                <h1 className="text-5xl font-bold text-right">${data ? getTotal() : ""}</h1>

                <div className="my-10 flex">

                <div className="flex flex-col justify-start mr-4">
                    <label for="order">Cost:</label>
                    <input onChange={(e) => setData({...data, cost: e.target.value})} value={data["cost"]} id="cost" className="border px-4 py-2 bg-white outline-none" type="number" name="cost" />
                  </div>

                  <div className="flex flex-col justify-start">
                    <label for="order">Order Number:</label>
                    <input onChange={(e) => setData({...data, order: e.target.value})} value={data["order"]} id="order" className="border px-4 py-2 bg-white outline-none" type="text" name="order" />
                  </div>

                </div>
              </div>
            </div>

            <div className="bg-white px-4 py-2">

            <div className="flex flex-col w-full mb-20">
              <div className="overflow-x-auto">
                <div className="py-2 inline-block min-w-full">
                  <div className="overflow-hidden">
                    <table className="min-w-full border-collapse">
                      <thead className="bg-white border-b">
                        <tr>
                          <th scope="col" className="border-b border-r text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            #
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-2 text-left">
                            Title
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Description
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Qty
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Rate
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Amount
                          </th>
                          <th scope="col" className="border-b border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            TAXABLE?
                          </th>
                          <th scope="col" className="w-10 border-b border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                          data["products"].map((x, index) => (
                            <tr key={x["barcode"] + index} className={`duration-300 border-b ${!Boolean(index % 2) ? "bg-white" : "bg-white"}`}>
                              <td className="border-t border-b border-r px-6 w-10 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                {index + 1}
                              </td>
                              <td className="border relative font-bold px-6 w-96 py-2 whitespace-nowrap text-sm text-gray-900">
                                {/* <select onChange={(e) => changeProduct(e.target.value, index)} className="w-full outline-none border shadow rounded px-2 py-1">
                                  <option>{x["title"]}</option>
                                  <option>Test</option>
                                </select> */}
                                <KobiSelect currentValue={x["title"]} callback={handleChangeProduct} index={index} products={product} />
                              </td>
                              <td className="border text-gray-900 w-full px-6 py-4 whitespace-nowrap">
                                <Textarea placeholder='Description' value={x["description"]} onChange={(e) => changeDescription(e.target.value, index)} className="w-full text-xs h-auto outline-none" />
                              </td>
                              <td className="border text-lg w-20 text-gray-900 px-6 py-4 whitespace-nowrap">
                                <div className="flex flex-col">
                                  <p className="text-xs">{x["stock"] ? x["stock"] : 0} in stock.</p>
                                  <input onChange={(e) => editQuantity(e.target.value, index)} value={x["quantity"]} className="w-20 outline-none border p-1" placeholder="Qty" type="number"  />
                                </div>
                              </td>
                              <td className="text-sm text-gray-900 font-bold px-6 py-4">
                                <div className="flex justify-center items-center flex-col h-full">
                                  <div className="mb-2 flex justify-center items-center">
                                  $<input onChange={(e) => changeRate(e.target.value, index)} value={x["rate"]} className="w-40 outline-none border p-1" placeholder="Rate" type="number" />
                                  </div>
                                  <div className="flex flex-row jusitify-start items-center">
                                    <div onClick={() => changeRate(x["contractorPricing"] ? x["contractorPricing"] : "0", index)} className="text-xs bg-green-200 hover:bg-green-300 duration-200 cursor-pointer flex flex-col m-1 p-1">
                                      <h3 className="text-[0.5rem] leading-[0.4rem]">Contractor</h3>
                                      ${x["contractorPricing"] ? x["contractorPricing"] : "0"}
                                    </div>
                                    <div onClick={() => changeRate(x["installerPricing"] ? x["installerPricing"] : "0", index)} className="text-xs bg-green-200 hover:bg-green-300 duration-200 cursor-pointer flex flex-col m-1 p-1">
                                      <h3 className="text-[0.5rem] leading-[0.4rem]">Installer</h3>
                                      ${x["installerPricing"] ? x["installerPricing"] : "0"}
                                    </div>
                                    <div onClick={() => changeRate(x["shopPricing"] ? x["shopPricing"] : "0", index)} className="text-xs bg-green-200 hover:bg-green-300 duration-200 cursor-pointer flex flex-col m-1 p-1">
                                      <h3 className="text-[0.5rem] leading-[0.4rem]">Shop</h3>
                                      ${x["shopPricing"] ? x["shopPricing"] : "0"}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="border w-24 text-sm text-gray-900 font-bold px-6 py-4 whitespace-nowrap">
                                <p className="w-24">
                                  ${Number(x["rate"] * x["quantity"]).toFixed(2)} 
                                </p>
                              </td>
                              <td className="border text-sm text-gray-900 font-bold px-6 py-4 whitespace-nowrap">
                                <svg onClick={() => deleteRow(index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:text-red-900 duration-300 cursor-pointer mx-auto ">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex flex-row">
                    <button onClick={() => addProduct()} className="px-4 py-2 border shadow rounded-lg border-black text-xs mr-2">Add Line</button>
                    <button onClick={() => setCreateProduct(true)} className="px-4 py-2 border shadow rounded-lg border-black text-xs mr-2">Add Product</button>
                    <button onClick={() => fetchProducts()} className="px-4 py-2 border shadow rounded-lg border-black text-xs mr-2">Fetch Product</button>
                  </div>
                  <div className="mt-4">
                    <h3>Message on Invoice...</h3>
                    <textarea onChange={(e) => setData({...data, message: e.target.value})} defaultValue={data["message"]} className="px-4 py-2 border outline-none w-96 h-40"></textarea>
                  </div>
                </div>
                <div className="flex justify-center items-center flex-col">
                  <h3 className="font-bold text-lg">Activities</h3>
                  <ul>
                    {
                      data["activities"].map(x => (
                        <li>
                          {x}
                        </li>
                      ))
                    }
                  </ul>
                </div>
                <div className="grid grid-cols-2 gap-x-20 gap-y-2">
                  <h3>
                    Subtotal:
                  </h3>
                  <span className="ml-1 font-bold">
                    ${data["products"].length === 1 ? Number(data["products"][0]["quantity"] * data["products"][0]["rate"]).toFixed(2) : getTotal()}
                  </span>
                  <h3>
                    Tax:
                  </h3>
                  <span className="ml-1 font-bold">
                    ${data["tax"] >= 1 ? Number((9.5 / 100) * getTotal()).toFixed(2) : 0.00}
                  </span>
                  <h3>Total</h3>
                  <span className="ml-1 font-bold">
                   ${data["tax"] >= 1 ? Number((9.5 / 100) * getTotal() + getTotal()).toFixed(2) : getTotal()}
                  </span>
                </div>
              </div>
            </div>

            </div>
          </div>
        }

        {/* footer */}
        <div className="bg-gray-500 py-2 px-4 fixed text-sm bottom-0 left-0 w-full h-30 flex justify-between items-center">
          <div className="flex justify-center items-center">
            <button onClick={() => {setActive(false); setTimeout(() => {setLoadedInvoice(false)}, 500)}} className="text-white border rounded-full px-6 py-2 hover:bg-white duration-300 hover:text-black">Cancel</button>
            <button className="text-white border rounded-full px-6 py-2 ml-2 hover:bg-white duration-300 hover:text-black">Clear</button>
            <button onClick={() => deleteInvoice()} className="text-white border rounded-full px-6 py-2 hover:bg-red-600 ml-2 bg-red-500 duration-300">Delete</button>
          </div>

          <div>
            <button onClick={() => {window.open("https://backend.azupin.glass/pdf/" + data["_id"] + "?type=Estimate", "_blank")}} className="text-white border rounded-full px-6 py-2 hover:bg-white duration-300 hover:text-black flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
              </svg>
              Print / Download
            </button>
          </div>

          <div className="flex">
            <button onClick={() => convertToInvoice()} className="text-white border rounded-full px-6 py-2 mr-2 hover:bg-white duration-300 hover:text-black">Convert To Invoice</button>
            <button onClick={() => save()} className="text-white border border-green-600 rounded-full px-6 py-2 bg-green-600">Save and close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenEstimate