import React, {useState, useEffect} from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios';
import moment from 'moment/moment';
import OpenGlass from '../components/OpenGlass';
import OpenOnlineOrder from '../components/OpenOnlineOrder';
import CreateInvoice from '../components/CreateInvoice';
import Textarea from 'react-expanding-textarea'
import LoadingIcon from '../components/LoadingIcon';

function Online() {

  const [ data, setData ] = useState([]);
  
  const [ filtered, setFiltered ] = useState([]);
  const [ search, setSearch ] = useState("");
  const [ createInvoice, setCreateInvoice ] = useState(false);

  const [ loading, setLoading ] = useState(true);

  const [ loadedInvoice, setLoadedInvoice ] = useState(false);

  const fetchData = async (e) => {

    const res = await axios.get("https://supplier-backend.azupin.glass/secretLink");

    setData(res["data"])

    filter(res["data"])
    setLoading(false)

  };

  const filter = (e = data) => {
    const filtrd = e.filter(x => 
      x["PO"].toLowerCase().includes(search.toLowerCase())
      ||
      x["order"].toLowerCase().includes(search.toLowerCase())
      ||
      x["customer"].toLowerCase().includes(search.toLowerCase())
    );

    setFiltered(filtrd);
  }

  useEffect(() => {

    fetchData();

    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    filter();

  }, [search, setSearch])

  return (
    <div className="lg:flex">

      <Navbar />

      {
        loadedInvoice
        ?
        <OpenOnlineOrder fetchData={fetchData} loadedOrder={loadedInvoice} setLoadedOrder={setLoadedInvoice} />
        :
        ""
      }

      <div className="overflow-y-scroll h-screen w-full">
        {
          loading
          ?
          <LoadingIcon />
          :
          <div className={`mx-auto mt-10`}>
            <div className="flex justify-between items-center px-10">
              <h3 className="font-bold text-lg">
                Online Orders!
              </h3>
              {/* <button onClick={() => setCreateInvoice(true)} className="px-4 py-2 font-bold bg-blue-400 rounded shadow text-white hover:bg-blue-600 duration-300">Create</button> */}
            </div>
            <div className="flex flex-row justify-start items-start">
              <div className="flex flex-col w-full">
                <div className="overflow-x-auto">
                  <div className="py-2 inline-block min-w-full">
                    <div className="overflow-hidden">
                      <table className="min-w-full">
                        <thead className="bg-white border-b">
                          <tr>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              Date
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              #
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2 text-left">
                              <input onChange={(e) => setSearch(e.target.value)} placeholder="Search..." className="w-full mb-2 px-2 py-1 border outline-none" />
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              Status
                            </th>
                            {/* <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              Stock
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            filtered.map((x, index) => (
                              <tr onClick={() => setLoadedInvoice(x["_id"])} key={x["_id"]} className={`hover:bg-blue-200 cursor-pointer duration-300 border-b ${!Boolean(index % 2) ? "bg-gray-100" : "bg-white"}`}>
                                <td className="px-6 w-28 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                  {moment(x["timestamp"]).format("L")}
                                </td>
                                <td className="px-6 w-28 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                  {x["PO"]}
                                </td>
                                <td className="text-sm text-gray-900 flex flex-col px-6 py-4 whitespace-nowrap">
                                  <h3 className="font-bold">
                                    {x["customer"]}
                                  </h3>
                                  <p className="text-xs truncate w-96">
                                    {x["supplier"]}
                                  </p>
                                </td>
                                <td className="text-lg text-gray-900 px-6 py-4 whitespace-nowrap">
                                  <h3 className="font-bold">
                                    {x["status"]}
                                  </h3>
                                </td>
                                {/* <td className="text-sm text-gray-900 font-bold px-6 py-4 whitespace-nowrap">
                                  {x["stock"]}
                                </td> */}
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        }
      </div>
    </div>
  )
}

export default Online