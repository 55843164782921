import React from "react";

function LoadingIcon() {
  return (
    <div className="h-full flex bg-opacity-80 bg-white w-full justify-center items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="212"
        height="192"
        version="1"
        viewBox="0 0 159 144"
        className="w-16 h-16 animate-bounce"
      >
        <path
          d="M658 1425c-159-30-277-95-394-217-87-90-150-201-179-312-17-69-13-84 9-32 34 82 130 197 226 271 110 85 215 124 350 132 182 10 328-42 464-165 71-66 82-72 121-72 92 0 129 101 63 175-150 171-428 264-660 220z"
          transform="matrix(.1 0 0 -.1 0 144)"
        ></path>
        <path
          d="M525 1162c-106-36-168-73-237-143-104-106-156-224-165-378-5-90 8-204 25-215 4-2 7 35 8 82 1 157 89 343 201 424 151 110 318 135 498 73 73-26 76-26 105-8 34 19 49 68 31 101-6 11-31 30-56 42-120 58-277 67-410 22zM826 813c-17-34-133-313-152-365l-13-38h100l22 50 22 50h162l21-50 21-50h50c28 0 51 3 51 6 0 4-39 101-87 215l-86 209h-48c-43 0-50-3-63-27zm90-149c33-74 28-85-33-77-26 3-49 7-51 8-1 1 5 19 14 41 9 21 20 49 25 62 5 12 11 22 14 22s17-25 31-56zM1147 833c-4-3-7-21-7-39v-33l132-3 131-3-131-135c-120-124-131-138-132-172v-38h370v79l-127 3-128 3 127 130c122 125 127 132 128 173v42h-178c-98 0-182-3-185-7z"
          transform="matrix(.1 0 0 -.1 0 144)"
        ></path>
        <path
          d="M288 703c-65-71-66-268-3-394C371 139 518 49 709 51c86 0 165 17 91 18-55 2-156 29-212 57-158 80-253 288-214 469 14 66 14 72-3 95-22 31-61 37-83 13z"
          transform="matrix(.1 0 0 -.1 0 144)"
        ></path>
      </svg>
    </div>
  );
}

export default LoadingIcon;
