import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment';

function OpenGlass({fetchData, setOpenGlass}) {

  // eslint-disable-next-line
  const [ loading, setLoading ] = useState(false);

  const submitGlass = async (e) => {

    e.preventDefault()

    console.log("HI")
    
    const obj = {
      
      inStock: e.target.inStock.value,

      stock: e.target.stock.value.split("\n"),
      category: "",
      title: e.target.title.value,
      description: e.target.description.value,
      costPrice: e.target.costPrice.value,
      invoiceNumber: e.target.invoiceNumber.value,

      customerName: e.target.customerName.value,
      from: e.target.from.value,

      dateDelivered: e.target.dateDelivered.value,

      timestamp: e.target.timestamp.value,
    }

    const res = await axios.post("https://backend.azupin.glass/glass/", obj);

    if(res["data"]){
      fetchData();
      window.location.reload()
    }
  }

  return (
    <div>
      <div className="z-20 fixed top-0 left-0">
        {
          loading
          ?
          <div className="h-screen flex bg-opacity-80 bg-white w-screen  justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 animate-spin">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
          </div>
          :
          <>
            <div className="w-screen bg-opacity-80 bg-white h-screen flex justify-center items-center">
              <div onClick={() => setOpenGlass("")} className="absolute top-0 left-0 w-screen h-screen cursor-pointer"></div>
              <div className="border shadow-lg p-10 z-10 bg-white rounded-lg w-[30rem]">
                <form onSubmit={submitGlass}>
                  <select id="from" defaultValue={"Liberty Glass Fabricators"} name="from" placeholder="FROM" className="w-full outline-none border px-4 py-2 mb-1">
                    <option value="Liberty Glass Fabricators">Liberty Glass Fabricators</option>
                    <option value="Trulite Glass Solutions">Trulite Glass Solutions</option>
                    <option value="Custom Glass Industries">Custom Glass Industries</option>
                  </select>
                  <label for="title">Title</label>
                  <input name="title" placeholder="Title" className="w-full outline-none border px-4 py-2 mb-1" type="text" />
                  <div>
                    <label for="customerName">P.O</label>
                    <input id="customerName" name="customerName" placeholder="P.O" className="w-full outline-none border px-4 py-2 mb-1" type="text" />
                  </div>
                  <div>
                    <label for="costPrice">Cost</label>
                    <input id="costPrice" name="costPrice" placeholder="Cost" className="w-full outline-none border px-4 py-2 mb-1" type="text" />
                  </div>
                  <div>
                    <label for="invoiceNumber">Invoice Number</label>
                    <input id="invoiceNumber" name="invoiceNumber" placeholder="Invoice Number" className="w-full outline-none border px-4 py-2 mb-1" type="text" />
                  </div>
                  <div className="flex flex-col">
                    <label>Stock <span className="text-xs">( each line = one new stock )</span></label>
                    <textarea name="stock" id="stock" className="px-4 py-2 outline-none w-full border mb-1"></textarea>
                  </div>
                  <label for="inStock">Arrived?</label>
                  <select id="inStock" name="inStock" placeholder="Stock" defaultValue={false} className="w-full outline-none border px-4 py-2 mb-1">
                    <option value={true}>Yes! It has arrived!</option>
                    <option value={false}>No! It's not here yet!</option>
                  </select>
                  <div className="w-full outline-none border px-4 py-2 mb-1">
                    <input defaultValue={moment().format("YYYY-MM-DD")} required className="w-full outline-none" id="dateDelivered" name="dateDelivered" type="date" />
                  </div>

                  <label>Created?</label>
                  <div className="w-full outline-none border px-4 py-2 mb-1">
                    <input defaultValue={moment().format("YYYY-MM-DD")} required className="w-full outline-none" id="timestamp" name="timestamp" type="date" />
                  </div>
                  <label for="description">Description</label>
                  <textarea id="description" name="description" placeholder='Description' className="outline-none w-full border px-4 py-2 h-20 mb-4" />
                  <button type="submit" className="px-4 py-2 bg-blue-500 rounded shadow-lg text-white font-bold text-lg uppercase w-full">Submit</button>
                </form>
              </div> 
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default OpenGlass