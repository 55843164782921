import React, {useState, useEffect} from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios';
import moment from 'moment/moment';
import OpenGlass from '../components/OpenGlass';
import LoadingIcon from '../components/LoadingIcon';
import EachGlass from '../components/EachGlass';
import AddGlass from '../components/AddGlass';
import Textarea from 'react-expanding-textarea'
import UpdateGlass from '../components/UpdateGlass';

function Glass() {

  const [ data, setData ] = useState([]);

  const [ search, setSearch ] = useState("");
  const [ filtered, setFiltered ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ loadingEdit, setLoadingEdit ] = useState(true);
  const [ createGlass, setCreateGlass ] = useState();
  const [ openGlass, setOpenGlass ] = useState(false);
  const [ copyOfData, setCopyOfData ] = useState({
    from: "Liberty Glass Fabricators"
  });

  const [ editing, setEditing ] = useState();

  const saveGlass = async (e) => {
    try{

      const result = await axios.post("https://backend.azupin.glass/glass", copyOfData);

      window.alert("Added successfully..")
      fetchData();

      setCopyOfData({
        from: "Liberty Glass Fabricators"
      })

    }catch{

      window.alert("Error....")
    }
  }

  const fetchData = async (e) => {

    const res = await axios.get("https://backend.azupin.glass/glass?custom=true");

    setData(res["data"])
    filter(res["data"])
    setLoading(false)
  };

  // const fetchEdit = async (e) => {

  //   // if(editing["_id"] === e){
  //   //   setEdit(false)
  //   //   setLoading(false)
  //   //   setEditing(false)
  //   // }else{
  //     setEdit(true)
  //     setLoadingEdit(true)
      
  //     const res = await axios.get("https://backend.azupin.glass/glass/" + e);
  
  //     setEditing(res["data"]);
  //     setLoadingEdit(false);
  //   // }
    

  // }

  const filter = (e = data) => {
    const filtrd = e.filter(x => 
      x["title"]?.toLowerCase().includes(search.toLowerCase())
      ||
      x["description"]?.toLowerCase().includes(search.toLowerCase())
      ||
      x["invoiceNumber"]?.toLowerCase().includes(search.toLowerCase())
      ||
      x["customerName"]?.toLowerCase().includes(search.toLowerCase())
      ||
      x["from"]?.toLowerCase().includes(search.toLowerCase())
    );

    setFiltered(filtrd)
    setFiltered(filtrd.sort((a, b) => moment(a["dateETA"]).format('DD-MM-YYYY') - moment(b["dateETA"]).format('DD-MM-YYYY')));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingEdit(true);

    const obj = {
      _id: editing["_id"],
      
      stock: e.target.stock.value.split("\n"),
      category: "", 
      title: e.target.title.value,
      description: e.target.description.value,
      costPrice: e.target.costPrice.value,
      invoiceNumber: e.target.invoiceNumber.value,

      customerName: e.target.customerName.value,
      from: e.target.from.value,
      inStock: e.target.inStock.value === "1" ? true : false,

      dateDelivered: e.target.dateDelivered.value,

      timestamp: e.target.timestamp.value,

    }
    
    const res = await axios.post("https://backend.azupin.glass/glass/edit", obj);
    
    if(res["data"]["messsage"] === "Success"){
      // fetchEdit(editing["_id"]);
      setTimeout(() => {
        setLoadingEdit(false);
        fetchData();
      }, 500);
    }else{
      console.log(res)
    }

  }

  const getTotal = (array) => {

    let total = 0;


    array.forEach(x => {
      total += Number(x["cost"].replace("$", ""))
    })

    return "$" + total.toFixed(2);

  }

  useEffect(() => {

    fetchData();

    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    filter();

  }, [search, setSearch])

  return (
    <div className="flex">
      <Navbar />

      {
        openGlass
        ?
        <OpenGlass fetchData={fetchData} setOpenGlass={setOpenGlass} />
        :
        ""
      }

      {/* {
        createGlass
        ?
        <AddGlass cb={setCreateGlass} />
        :
        ""
      } */}

      <div className="overflow-y-scroll w-full h-screen">
        {
          loading
          ?
          <LoadingIcon />
          :
          <div className={`mx-auto mt-10`}>
            <div className="flex justify-between items-center px-10">
              <h3 className="font-bold text-lg">
                Glass
              </h3>
              <button onClick={() => setCreateGlass(!createGlass)} className="px-4 py-2 font-semibold border shadow text-xs hover:bg-black hover:text-white duration-300">Add Line</button>
            </div>

            <div className="flex flex-row justify-evenly items-center gap-4 p-4">
                <div className="p-4 flex justify-center items-center flex-col bg-white shadow border mx-auto flex-1 h-60">
                  <h3 className="uppercase font-semibold">{moment().format("MMMM")} Orders</h3>
                  <p className="text-2xl font-bold">{data.filter(x => moment(x["timestamp"]).format("mm") === moment(x["timestamp"]).format("mm")).length}</p>
                </div>
                <div className="p-4 flex justify-center items-center flex-col bg-white shadow border mx-auto flex-1 h-60">
                  <h3 className="uppercase font-semibold">{moment().format("MMMM")} Spent</h3>
                  <p className="text-2xl font-bold">{getTotal(data.filter(x => moment(x["timestamp"]).format("mm") === moment(x["timestamp"]).format("mm")))}</p>
                </div>
                <div className="p-4 flex justify-center items-center flex-col bg-white shadow border mx-auto flex-1 h-60">
                  <h3 className="uppercase font-semibold">Total Orders</h3>
                  <p className="text-2xl font-bold">{data.length}</p>
                </div>
                <div className="p-4 bg-white shadow border mx-auto flex-1 h-60">
                  <UpdateGlass fetchData={fetchData} />
                </div>
              </div>

              
      {
        createGlass
        ?
        <div className="w-full relative h-auto col-span-8 border-t pb-20 pt-6 px-10 grid lg:grid-cols-7 gap-10">
          
          <div className="col-span-3">
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Title / Customer Name</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, title: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder='Title' value={copyOfData["title"]} />
            </div>
            <div className="flex flex-row w-full justify-between items-center mb-6 gap-2">
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">PO Number</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, customerName: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="PO Number" value={copyOfData["customerName"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">From</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, from: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="Liberty" value={copyOfData["from"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Order Number</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, orderNumber: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="5546" value={copyOfData["orderNumber"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Invoice Number</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, invoiceNumber: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="5546" value={copyOfData["invoiceNumber"]} />
              </div>
            </div>
            <div className="flex flex-row w-full justify-between items-center mb-6 gap-4">
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">ETA</label>
                <input type="date" className="bg-white border mb-2 outline-none z-10" onChange={(e) => {setCopyOfData({...copyOfData, dateETA: String(e.target.value)})}} value={copyOfData["dateETA"] ? new Date(copyOfData["dateETA"]).toISOString().split("T")[0] : ""} />
                {/* <input onChange={(e) => setCopyOfData({...copyOfData, key: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CATALYST-60X76-B" value={copyOfData["customerName"]} /> */}
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Delivered</label>
                <input type="date" className="bg-white border mb-2 outline-none z-10" onChange={(e) => {setCopyOfData({...copyOfData, dateDelivered: String(e.target.value)})}} value={copyOfData["dateDelivered"] ? new Date(copyOfData["dateDelivered"]).toISOString().split("T")[0] : ""} />
                {/* <input onChange={(e) => setCopyOfData({...copyOfData, key: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CATALYST-60X76-B" value={copyOfData["customerName"]} /> */}
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Picked Up</label>
                <input type="date" className="bg-white border mb-2 outline-none z-10" onChange={(e) => {setCopyOfData({...copyOfData, datePickedUp: String(e.target.value)})}} value={copyOfData["datePickedUp"] ? new Date(copyOfData["datePickedUp"]).toISOString().split("T")[0] : ""} />
                {/* <input onChange={(e) => setCopyOfData({...copyOfData, barcode: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CAT6076B" value={copyOfData["invoiceNumber"]} /> */}
              </div>
            </div>
          </div>

          <div className="col-span-1">
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Paid / Check No.</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, paid: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="100" value={copyOfData["paid"]} />
            </div>
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Cost</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, cost: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="100" value={copyOfData["cost"]} />
            </div>
            <div className="flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Sold</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, customerPaid: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="100" value={copyOfData["customerPaid"]} />
            </div>
          </div>

          <div className="col-span-3">
            <div className="flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Notes</label>
              <Textarea placeholder='' value={copyOfData["description"]} onChange={(e) => setCopyOfData({...copyOfData, description: e.target.value})} className="border-b border-gray-500 focus:border-blue-700 py-2 w-full text-xs h-auto outline-none" />
            </div>
            <div className="mt-2 flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Description</label>
              <Textarea placeholder='' value={copyOfData["stock"]?.join("\n")} onChange={(e) => setCopyOfData({...copyOfData, stock: e.target.value.split("\n")})} className="border-b border-gray-500 focus:border-blue-700 py-2 w-full text-xs h-auto outline-none" />
            </div>
          </div>

          <div className="w-full border-t absolute bottom-0 left-0 h-16 px-10 flex justify-between items-center">
            <div>
            </div>
            <div>
              <button onClick={saveGlass} className="bg-green-400 px-4 py-2 text-white font-semibold rounded-lg shadow tranform hover:scale-105 duration-200">
                Save
              </button>
            </div>
          </div>

        </div>
        :
        ""
      }
            <div className="flex flex-row w-full items-start justify-start">
              <div className="w-full overflow-x-auto">
                <div className="py-2 inline-block min-w-full">
                  <div className="overflow-hidden">
                      <div className="bg-white border-b border-t w-full">
                        <div className="grid lg:grid-cols-11 items-center">
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            Date
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            PO
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            Title
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            Quantity
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            Paid
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            Cost
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            Sale
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            From
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            ETA
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            Arrived
                          </div>
                          <div scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left">
                            Picked Up
                          </div>
                        </div>
                      </div>
                      <div>
                        {
                          filtered.map((x, index) => (
                            <EachGlass key={x["_id"]} x={x} index={index} fetchData={fetchData} />
                            // <div onClick={() => setEdit(x["_id"])} key={x["_id"]} className={`hover:bg-blue-200 cursor-pointer text-xs grid lg:grid-cols-11 items-center duration-300 border-b ${!Boolean(index % 2) ? "bg-gray-100" : "bg-white"}`}>
                            //   <div className="flex-1 px-2 py-1 whitespace-nowrap font-medium text-gray-900">
                            //       {moment(x["timestamp"]).format("L")}
                            //   </div>
                            //   <div className="flex-1 text-gray-900 px-2 py-1 whitespace-nowrap">
                            //     <h3 className="font-bold">
                            //       {x["customerName"].length <= 12 ? x["customerName"] : x["customerName"].substr(0, 12) + "..."}
                            //     </h3>
                            //   </div>
                            //   <div className="flex-1 text-gray-900 px-2 py-1 whitespace-nowrap">
                            //     <h3 className="font-bold">
                            //       {x["title"].length <= 12 ? x["title"] : x["title"].substr(0, 12) + "..."}
                            //     </h3>
                            //     <p>#5443</p>
                            //   </div>
                            //   <div className="flex-1 text-gray-900 font-normal px-2 py-1 whitespace-nowrap">
                            //     Amanda
                            //   </div>
                            //   <div className="flex-1 text-gray-900 font-normal px-2 py-1 whitespace-nowrap">
                            //     {x["costPrice"]}
                            //   </div>
                            //   <div className="flex-1 text-gray-900 font-normal px-2 py-1 whitespace-nowrap">
                            //     {x["costPrice"]}
                            //   </div>
                            //   <div className="flex-1 text-gray-900 font-normal px-2 py-1 whitespace-nowrap">
                            //     {x["costPrice"]}
                            //   </div>
                            //   <div className="flex-1 text-gray-900 px-2 py-1 whitespace-nowrap">
                            //     <h3 className="text-xs font-bold">
                            //       {x["from"].length <= 17 ? x["from"] : x["from"].substr(0, 17) + "..."}
                            //     </h3>
                            //     <p>
                            //       Ref: {x["invoiceNumber"] ? x["invoiceNumber"] : "N/A"}
                            //     </p>
                            //   </div>
                            //   <div className="flex-1 text-gray-900 font-bold px-2 py-1 whitespace-nowrap">
                            //     {(moment(x["dateDelivered"]).format("L"))}
                            //   </div>
                            //   <div className="flex-1 text-gray-900 font-bold px-2 py-1 whitespace-nowrap">
                            //     {(moment(x["dateDelivered"]).format("L"))}
                            //   </div>
                            //   <div className="flex-1 text-gray-900 font-bold px-2 py-1 whitespace-nowrap">
                            //     {(moment(x["dateDelivered"]).format("L"))}
                            //   </div>
                            // </div>
                          ))
                        }
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Glass