import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios';
import Textarea from 'react-expanding-textarea'
import OpenProduct from './OpenProduct';
import Select from 'react-select'
import CreateClient from './CreateClient';
import moment from 'moment';
import KobiSelect from './KobiSelect';
import LoadingIcon from './LoadingIcon';

function CreateGroup({fetchData, createInvoice, setCreateInvoice}) { 

  const [ active, setActive ] = useState(true);
  const [ data, setData ] = useState({
    title: "",
    description: "",
    image: "",

    items: [],

    key: "",
    type: "",
    price: 0
  });

  const [ products, setProduct ] = useState([]);

  const [ loading, setLoading ] = useState(false);

  const save = async () => {

    setLoading(true)

    console.log(data)

    const res = await axios.post("https://backend.azupin.glass/groups", data);

    if(res){
      setTimeout(() => {
        setLoading(false);
        // fetchData();
        // setActive(false);
        setTimeout(() => {
          // setCreateInvoice(false);
        }, 500);
      }, 1000);
    }
  }

  const editQuantity = (val, index) => {
    let copy = data;

    copy["items"][index]["quantity"] = Number(val);

    setData({...data, items: copy["items"]})

  }

  const changeDescription = (val, index) => {
    let copy = data;

    copy["items"][index]["description"] = val;
    
    setData({...data, items: copy["items"]})
  }

  const changeThisProduct = (val, index, field) => {
    
    let copy = data;

    copy["items"][index][field] = val;
    
    setData({...data, items: copy["items"]})
  }

  const changeProduct = (val, index) => {
    let copy = data;

    copy["items"][index]["title"] = val;

    setData({...data, items: copy["items"]});
  }

  const deleteRow = (index) => {

    const changed = data["items"].filter((x, i) => i !== index);

    setData({...data, items: changed})

  }

  const addProduct = () => {
    setData({...data, items: [...data["items"], {
        _id: "", 
        title: "", 
        description: "", 
        quantity: 1, 
        rate: 0, 
        tax: true,
        ready: 0,
      }]
    })
  }

  const fetchProducts = async () => {
    const res = await axios.get("https://backend.azupin.glass/product");

    setProduct(res["data"])
    
  }

  const handleChangeProduct = async (e, index) => {
    
    const dta = products.find(x => x["_id"] === e);

    changeThisProduct(dta["image"], index, "image")
    changeThisProduct(dta["description"], index, "description")
    changeProduct(dta["title"], index);
    changeThisProduct(dta["price"], index, "shopPricing");
    changeThisProduct(dta["stock"], index, "stock");
  }

  useEffect(() => {

    fetchProducts()

    // eslint-disable-next-line
  }, [])

  return (
    <div className={`${active ? "slide-in-top" : "slide-out-top"} fixed top-0 left-0 z-[100]`}>
      <div className='bg-white overflow-y-scroll relative flex justify-center items-center w-screen h-screen top-0 left-0 z-[100]'>
        <div className="flex justify-between items-center absolute top-0 left-0 py-2 px-4 w-full">
          <div className="flex justify-start items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
            <h3 className="font-bold text-xl">
              Group Creator
            </h3>
          </div>
          <svg onClick={() => {setActive(false); setTimeout(() => {setCreateInvoice(false)}, 500)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-10 h-10 hover:text-red-500 duration-300">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        {
          loading
          ?
          <LoadingIcon />
          :
          <div className="w-full h-full">
            <div className="px-4 pt-14 pb-4 bg-gray-100 flex flex-row justify-between">
              <div className="flex flex-row justify-start items-start">

                <div className="flex flex-col justify-start items-start mr-4 w-96">
                  <div className="flex flex-col justify-start items-start w-full">
                    <label for="title">Title:</label>
                    <input name="title" id="title" onChange={(e) => setData({...data, title: e.target.value})} value={data["title"]} className="bg-white border px-4 py-[.4rem] outline-none w-full" />
                  </div>
                  <label for="description">Description:</label>
                  <textarea onChange={(e) => setData({...data, description: e.target.value})} value={data["description"]} id="description" name="description" className="px-4 py-2 border w-full">
                  </textarea>
                </div>
                <div className="flex flex-col justify-start items-start w-72">
                  <div className="flex flex-col justify-start items-start">
                    <label for="image">Image:</label> 
                    <input name="image" id="image" onChange={(e) => setData({...data, image: e.target.value})} value={data["image"]} className="bg-white border px-4 py-[.4rem] outline-none" />
                  </div>
                  <div className="flex flex-col justify-start items-start">
                    <label for="key">Key:</label>
                    <input name="key" id="key" onChange={(e) => setData({...data, key: e.target.value})} value={data["key"]} className="bg-white border px-4 py-[.4rem] outline-none" />
                  </div>
                </div>
              </div>
              <div>
              </div>
              <div>
                <div className="my-10 flex">

                <div className="flex flex-col justify-start mr-4">
                    <label for="price">Cost:</label>
                    <input onChange={(e) => setData({...data, price: e.target.value})} value={data["price"]} id="price" className="border px-4 py-2 bg-white outline-none" type="number" name="price" />
                  </div>

                </div>
              </div>
            </div>

            <div className="bg-white px-4 py-2">

            <div className="flex flex-col w-full">
              <div className="overflow-x-auto">
                <div className="py-2 inline-block min-w-full">
                  <div className="overflow">
                    <table className="min-w-full border-collapse">
                      <thead className="bg-white border-b">
                        <tr>
                          <th scope="col" className="border-b border-r text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            #
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-2 text-left">
                            Title
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Description
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Qty
                          </th>
                          <th scope="col" className="w-10 border-b border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data["items"].map((x, index) => (
                            <tr key={x["barcode"] + index} className={`duration-300 border-b ${!Boolean(index % 2) ? "bg-white" : "bg-white"}`}>
                              <td className="border-t border-b border-r px-6 w-10 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                {index + 1}
                              </td>
                              <td className="border relative font-bold px-6 w-96 py-2 whitespace-nowrap text-sm text-gray-900">
                                {/* <select onChange={(e) => changeProduct(e.target.value, index)} className="w-full outline-none border shadow rounded px-2 py-1">
                                  <option>{x["title"]}</option>
                                  <option>Test</option>
                                </select> */}
                                <KobiSelect currentValue={x["title"]} callback={handleChangeProduct} index={index} products={products} />
                              </td>
                              <td className="border text-gray-900 w-full px-6 py-4 whitespace-nowrap">
                                <Textarea placeholder='Description' value={x["description"]} onChange={(e) => changeDescription(e.target.value, index)} className="w-full text-xs h-auto outline-none" />
                              </td>
                              <td className="border text-lg w-20 text-gray-900 px-6 py-4 whitespace-nowrap">
                                <div className="flex flex-col">
                                  <input onChange={(e) => editQuantity(e.target.value, index)} value={x["quantity"]} className="w-32 outline-none border p-1" placeholder="Qty" type="number"  />
                                </div>
                              </td>
                              <td className="border text-sm text-gray-900 font-bold px-6 py-4 whitespace-nowrap">
                                <svg onClick={() => deleteRow(index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:text-red-900 duration-300 cursor-pointer mx-auto ">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex flex-row">
                    <button onClick={() => addProduct()} className="px-4 py-2 border shadow rounded-lg border-black text-xs mr-2">Add Line</button>
                  </div>
                </div>
              </div>
            </div>

            </div>
          </div>
        }

        {/* footer */}
        <div className="bg-gray-500 py-2 px-4 fixed text-sm bottom-0 left-0 w-full h-30 flex justify-between items-center">
          <div className="flex justify-center items-center">
            <button onClick={() => {setActive(false); setTimeout(() => {setCreateInvoice(false)}, 500)}} className="text-white border rounded-full px-6 py-2 hover:bg-white duration-300 hover:text-black">Cancel</button>
            <button className="text-white border rounded-full px-6 py-2 ml-2 hover:bg-white duration-300 hover:text-black">Clear</button>
          </div>

          <div className="flex">
            <button onClick={() => save()} className="text-white border border-green-600 rounded-full px-6 py-2 bg-green-600">Save and close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateGroup