import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios';
import Textarea from 'react-expanding-textarea'
import OpenProduct from './OpenProduct';
import CreateClient from './CreateClient';
import Select from 'react-select'
import KobiSelect from './KobiSelect';
import moment from 'moment';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import BarcodeReader from 'react-barcode-reader'
import DateTimePicker from 'react-datetime-picker';
import LoadingIcon from './LoadingIcon';

function CheckInvoice({fetchData, loadedInvoice, setLoadedInvoice}) { 

  const [ createProduct, setCreateProduct ] = useState(false);
  const [ active, setActive ] = useState(true);
  const [ customers, setCustomers ] = useState([]);
  const [ createClient, setCreateClient ] = useState(false);
  const [ data, setData ] = useState({
        // customer: "Robles Glass Supplies Inc",
        // billingAddress: "1515 N Kraemer Blvd Ste M, Anaheim, CA 92806",

        // user: "Kobi",
        // order: "6149",
        // PO: "Placentia Loreto",
        // products: [
        //   {
        //     "_id": "SOME-ID-NUMBER",
        //     "title": `AZ-DS01 60"W X 66"H in Chrome Finish`,
        //     "description": `Frameless Double Sliding Shower Door with Hardware Kit. \n\n Type: Double Towel Bars \n Thickness: 3/8" Clear Tempered \n Finish: Chrome \n Size: 60"x66"`,
        //     "quantity": 1,
        //     "rate": 362.4,
        //     "tax": false,
        //   }
        // ],

        handler: localStorage.getItem("username"),

        // terms: "COD",

        // tax: 0,

        // deposit: 0,

        // message: "PO: Placentia Loreto",
        // cost: 240,
        salePrice: 362.4,
  });

  const [ product, setProduct ] = useState([]);

  const [ loading, setLoading ] = useState(true);

  const fetchInvoice = async () => {
    const res = await axios.get("https://backend.azupin.glass/invoice/" + loadedInvoice);

    setData(res["data"])
    setLoading(false)
  }

  const getTotal = () => {
    let total = 0;
    data["products"].forEach(x => {
      total += x["quantity"] * x["rate"]
    })

    return total - data["discount"]
  }

  const save = async () => {

    setLoading(true)

    const res = await axios.post("https://backend.azupin.glass/invoice/edit", {...data, tax: data["tax"] >= 1 ? Number((9.5 / 100) * getTotal()).toFixed(2) : 0.00, salePrice: getTotal()});
    // const stock = await axios.post("http://localhost:5000/product/stock", {stock: data["products"]});

    if(res["data"]["message"] === "success"){
      setTimeout(() => {
        setLoading(false);  
        fetchData();
        setActive(false);
        setTimeout(() => {setLoadedInvoice(false)}, 500)
      }, 1000);
    }
  }

  const saveInvoice = async () => {

    setLoading(true)

    const res = await axios.post("https://backend.azupin.glass/invoice/edit", {...data});
    
    if(res["data"]["message"] === "success"){
      setTimeout(() => {
        setLoading(false);
        fetchData();
      }, 1000);
    }
  }

  const handleStockSave = async (x, index) => {

    const DATA_BODY = {
      product: x,
      orderId: data["_id"],
      index: index
    }

    const res = await axios.post("https://backend.azupin.glass/product/stock", DATA_BODY);

    console.log(res);


  }

  const callbac = (a) => {
    
    const filteredBOOL = data["products"].findIndex(x => x["barcode"]?.toUpperCase() === a.toUpperCase());

    if(filteredBOOL >= 0 ){
      
      let copyOfData = {...data};

      if(copyOfData["products"][filteredBOOL]["ready"] === copyOfData["products"][filteredBOOL]["quantity"]){
        return window.alert("This line is already complete, no more products needed.")
      }
      

      copyOfData["products"][filteredBOOL]["ready"] = !isNaN(copyOfData["products"][filteredBOOL]["ready"]) ? copyOfData["products"][filteredBOOL]["ready"] + 1 : 1;

      return setData({...copyOfData})
    }else{
      return window.alert("Invoice does not contain this item. Please recheck. " + a)
    };

  }

  useEffect(() => {

    fetchInvoice();

  }, [])
  
  return (
    <div className={`${active ? "slide-in-top" : "slide-out-top"} fixed top-0 left-0 z-10`}>
      <BarcodeReader
        onError={callbac}
        onScan={callbac}
      />
      <div className='bg-white relative flex justify-center items-center w-screen overflow-y-scroll h-screen top-0 left-0 z-[100]'>
        <div className="flex justify-between items-center absolute top-0 left-0 py-2 px-4 w-full">
          <div className="flex justify-start items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
            <h3 className="font-bold text-xl">
              Packing #{data["order"]}
            </h3>
          </div>
          <svg onClick={() => {setActive(false); setTimeout(() => {setLoadedInvoice(false)}, 500)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-10 h-10 hover:text-red-500 duration-300">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        {
          loading
          ?
          <LoadingIcon />
          :
          <div className="w-full h-full">
            <div className="px-4 pt-14 pb-4 bg-gray-100 flex flex-row justify-between">
              <div className="flex flex-row justify-start items-start">

                <div className="flex flex-col justify-start items-start mr-4 w-72">
                  <label for="customer">Customer:</label>
                    <div className="w-full flex justify-start items-center bg-white border px-4 py-[.4rem]">
                    {data["customer"]}
                  </div>
                  <label for="billingAddress">Billing Address:</label>
                  <textarea onChange={(e) => setData({...data, billingAddress: e.target.value})} value={data["billingAddress"]} id="billingAddress" name="billingAddress" className="px-4 py-2 border w-full">
                  </textarea>
                  <label for="quoteDate">Quote Date</label>
                  <div className="bg-white border px-4 py-[.5rem]">
                    {data["quoteDate"]}
                  </div>

                </div>
                <div className="flex flex-col justify-start items-start w-72">
                  <div className="flex flex-col justify-start items-start">
                    <label for="PO">P.O</label>
                    <input name="PO" id="PO" onChange={(e) => setData({...data, PO: e.target.value})} value={data["PO"]} className="bg-white border px-4 py-[.4rem] outline-none" />
                  </div>
                  <label for="billingAddress">Shipping Address:</label>
                  <textarea onChange={(e) => setData({...data, shippingAddress: e.target.value})} value={data["shippingAddress"]} id="shippingAddress" name="billingAddress" className="px-4 py-2 border w-full">
                  </textarea>
                  <div className="flex flex-col justify-start items-start">
                    <label for="orderDate">Order Date</label>
                    <div className="bg-white border px-4 py-[.5rem]">
                      {moment(data["orderDate"]).format('YYYY-MM-DD, h:mm:ss a')}
                    </div>
                  </div>  
                </div>
              </div>
              <div>
                <h3 className="uppercase text-xs text-right">BALANCE DUE</h3>
                <h1 className="text-5xl font-bold text-right">${data["tax"] >= 1 ? Number((9.5 / 100) * getTotal() + getTotal() - data["discount"]).toFixed(2) : getTotal()}</h1>

                <div className="my-10 flex">

                  <div className="flex flex-col justify-start">
                    <label for="order">Order Number:</label>
                    <input disabled onChange={(e) => setData({...data, order: e.target.value})} value={data["order"]} id="order" className="border px-4 py-2 bg-white outline-none" type="text" name="order" />
                  </div>

                </div>
              </div>
            </div>

            <div className="bg-white px-4 py-2">

            <div className="flex flex-col w-full mb-20">
              <div className="overflow-x-auto">
                <div className="py-2 inline-block min-w-full">
                  <div className="overflow-hidden">
                    <table className="min-w-full border-collapse">
                      <thead className="bg-white border-b">
                        <tr>
                          <th scope="col" className="border-b border-r text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            #
                          </th>
                          <th scope="col" className="w-72 border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-2 text-left">
                            Title
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Description
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Qty
                          </th>
                          <th scope="col" className="w-10 border-b border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                          data["products"].map((x, index) => (
                            <tr key={index} className={`duration-300 border-b ${!Boolean(index % 2) ? "bg-white" : "bg-white"}`}>
                              <td className="border-t border-b border-r px-6 w-10 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                {index + 1}
                              </td>
                              <td className="border relative font-bold px-6 w-96 py-2 whitespace-nowrap text-sm text-gray-900">
                                {/* <select onChange={(e) => changeProduct(e.target.value, index)} className="w-full outline-none border shadow rounded px-2 py-1">
                                  <option>{x["title"]}</option>
                                  <option>Test</option>
                                </select> */}
                                <div className="flex flex-col min-w-72">
                                  <div className="text-xs font-normal">
                                  {x["barcode"]}
                                  </div>
                                  {x["title"]}
                                </div>
                              </td>
                              <td className="border text-gray-900 w-full px-6 py-4 whitespace-nowrap">
                                <Textarea value={x["description"]} className="w-full text-xs h-auto outline-none" />
                              </td>
                              <td className="border text-lg w-20 text-gray-900 px-6 py-4 whitespace-nowrap">
                                {x["quantity"]}
                              </td>
                              <td className="justify-center items-center text-sm text-gray-900 font-bold px-6 py-4 whitespace-nowrap">
                                <div className="flex flex-col w-40">
                                  {
                                  x["ready"] === x["quantity"] 
                                  ? 
                                    <div className="flex justify-center items-center flex-row">
                                      <h3 className="text-lg font-bold">
                                        {x["ready"] + "/" + x["quantity"]}
                                      </h3>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                      </svg>
                                    </div>
                                  :
                                    <div className="flex justify-center items-center flex-row">
                                      <h3 className="text-lg font-bold">
                                      {`${x["ready"] ? x["ready"] : 0}/${x["quantity"]}`} 
                                      </h3>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                      </svg>
                                    </div>
                                  }
                                  {/* {JSON.stringify(x["ready"])} */}
                                  {
                                    x["saved"]
                                    ?
                                    ""
                                    :
                                    <button onClick={() => handleStockSave(x, index)} className="bg-green-500 text-white font-bold py-2 rounded shadow hover:shadow-2xl duration-200">Upload</button>
                                  }
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-start">
                <div>
                  <div className="mt-4">
                    <h3>Message on Invoice...</h3>
                    <textarea onChange={(e) => setData({...data, message: e.target.value})} defaultValue={data["message"]} className="px-4 py-2 border outline-none w-96 h-40"></textarea>
                  </div>
                </div>
                <div className="flex justify-center items-center flex-col">
                  <h3 className="font-bold text-lg">Activities</h3>
                  <ul className="h-60 w-96 overflow-y-scroll">
                    {
                      data["activities"].map(x => (
                        <li>
                          {x}
                        </li>
                      ))
                    }
                  </ul>
                </div>
                <div className="grid grid-cols-2 gap-x-20 gap-y-2">

                </div>
              </div>
            </div>

            </div>
          </div>
        }

        {/* footer */}
        <div className="bg-gray-500 py-2 px-4 fixed text-sm bottom-0 left-0 w-full h-30 flex justify-between items-center">
          <div className="flex justify-center items-center">
            <button onClick={() => {setActive(false); setTimeout(() => {setLoadedInvoice(false)}, 500)}} className="text-white border rounded-full px-6 py-2 hover:bg-white duration-300 hover:text-black">Cancel</button>
            <button className="text-white border rounded-full px-6 py-2 ml-2 hover:bg-white duration-300 hover:text-black">Clear</button>
          </div>

          <div className="flex gap-2">
            <button onClick={() => {window.open("https://backend.azupin.glass/pdf/" + data["_id"] + "?type=Packing", "_blank")}} className="text-white border rounded-full px-6 py-2 hover:bg-white duration-300 hover:text-black flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
              </svg>
              Print / Download
            </button>
          </div>

          <div className="flex">
            <button onClick={() => saveInvoice()} className="text-white border rounded-full px-6 py-2 mr-2 hover:bg-white duration-300 hover:text-black">Save</button>
            <button onClick={() => save()} className="text-white border border-green-600 rounded-full px-6 py-2 bg-green-600">Save and close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckInvoice