import React, { useState } from 'react';
import axios from 'axios';

function CreateClient({fetchCustomers, createClient, setCreateClient}) {
  
  const [loading, setLoading] = useState(false);
  const [ active, setActive ] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const obj = {
      title: e.target.title.value,
      billingAddress: e.target.billingAddress.value,
      level: Number(e.target.level.value),
      message: e.target.message.value,
      due: e.target.due.value,
      phone: e.target.phoneNumber.value,
      handler: e.target.handler.value,
      email: e.target.email.value
    };

    const res = await axios.post("https://backend.azupin.glass/customer/", obj);

    if(res){
      fetchCustomers();
      setLoading(false);
      setActive(false);
      setTimeout(() => {
        setCreateClient(false);
      }, 500);
    }
  }

  return (
    <div className="z-10 fixed top-0 right-0 h-screen w-screen">
      <div className={`${active ? "slide-in-right" : "slide-out-right"} bg-white w-[45%] h-full p-10 z-[5] absolute top-0 right-0`}>

        <h3 className="text-xl font-bold uppercase mb-4">Create a Client</h3>
        {
          !loading
          ?
          <form onSubmit={handleSubmit} className="flex flex-col">
            
            <div className="w-full flex mb-2">
              <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
                <label for="title" className="text-xs">Title</label>
                <input required className="w-full px-2 py-1 outline-none border" placeholder='Title' id="title" name="title" />
              </div>
              <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
                <label for="handler" className="text-xs">Handler</label>
                <input required  className="px-2 py-1 outline-none border w-full" placeholder='Handler' name="handler" />
              </div>
              <div className="flex flex-col justify-start items-start">
                <label for="level" className="text-xs">Level</label>
                <select value={1} className="px-4 py-2 border outline-none h-[2.1rem]" name="level">
                  <option>1</option>
                  <option>2</option>
                </select>
              </div>
            </div>
            <div className="w-full flex mb-2">
              <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
                <label for="title" className="text-xs">Phone</label>
                <input type="tel"  className="px-2 py-1 outline-none border w-full" placeholder='Phone' name="phoneNumber" />
              </div>
              <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
                <label for="title" className="text-xs">Email</label>
                <input type="email" className="px-2 py-1 mr-2 outline-none border w-full" placeholder='Email' name="email" />
              </div>
              <div className="flex flex-1 w-full flex-col justify-start items-start">
                <label for="due" className="text-xs">Balance</label>
                <input className="px-2 py-1 outline-none border w-full" placeholder='Balance' name="due" type="float" />
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-full mb-2">
              <label for="billingAddress" className="text-xs">billingAddress</label>
              <textarea required className="px-2 py-1 outline-none w-full border" placeholder='billingAddress' name="billingAddress" />
            </div>
            <div className="flex flex-col justify-start items-start w-full mb-2">
              <label for="message" className="text-xs">Message</label>
              <input className="px-2 py-1 outline-none border w-full" placeholder='Message' name="message" />
            </div>

            <button className="px-4 py-2 bg-blue-400">Submit</button>

          </form>
          :
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mx-auto w-16 h-16 animate-spin">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
        }

      </div>
      <div onClick={() => {setActive(false); setTimeout(() => {setCreateClient(false)}, 500)}} className="bg-black w-full h-full bg-opacity-50 absolute top-0 cursor-pointer left-0"></div>
    </div>
  )
}

export default CreateClient