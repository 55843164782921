import React, {useState, useEffect} from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios';
import moment from 'moment/moment';
import { Fade } from 'react-awesome-reveal'
import OpenProduct from '../components/OpenProduct';
import EditProduct from '../components/EditProduct';
import LoadingIcon from '../components/LoadingIcon';

function Product() {

  const [ data, setData ] = useState([]);
  
  const [ filtered, setFiltered ] = useState([]);
  const [ search, setSearch ] = useState("");
  const [ createProduct, setCreateProduct ] = useState(false);
  const [ PRODUCT_ID, set_PRODUCT_ID ] = useState(""); 

  const [ loading, setLoading ] = useState(true);

  const fetchData = async (e) => {

    const res = await axios.get("https://backend.azupin.glass/product");

    setData(res["data"])

    filter(res["data"])
    setLoading(false)

  };

  const filter = (e = data) => {
    const filtrd = e.filter(x => 
      x["title"].toLowerCase().includes(search.toLowerCase())
      ||
      x["description"].toLowerCase().includes(search.toLowerCase())
    );

    setFiltered(filtrd);
  }

  useEffect(() => {

    fetchData();

    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    filter();

  }, [search, setSearch])

  return (
    <div className="flex">
      <Navbar />

      {
        createProduct
        ?
        <OpenProduct fetchProducts={fetchData} setCreateProduct={setCreateProduct} />
        :
        ""
      }

      {
        PRODUCT_ID
        ?
        <EditProduct fetchProducts={fetchData} PRODUCT_ID={PRODUCT_ID} set_PRODUCT_ID={set_PRODUCT_ID} />
        :
        ""
      }

      <div className="overflow-y-scroll h-screen w-full">
        {
          loading
          ?
          <LoadingIcon />
          :
          <div className={`mx-auto mt-10`}>
            <div className="flex justify-between items-center px-10">
              <h3 className="font-bold text-lg">
                Products!
              </h3>
              <button onClick={() => setCreateProduct(true)} className="px-4 py-2 font-bold bg-blue-400 rounded shadow text-white hover:bg-blue-600 duration-300">Create</button>
            </div>
            <div className="flex flex-row justify-start items-start">
              <div className="flex flex-col w-full">
                <div className="overflow-x-auto">
                  <div className="py-2 inline-block min-w-full">
                    <div className="overflow-hidden">
                      <table className="min-w-full">
                        <thead className="bg-white border-b">
                          <tr>
                            {/* <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              #
                            </th> */}
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2 text-left">
                              <input onChange={(e) => setSearch(e.target.value)} placeholder="Search..." className="w-full mb-2 px-2 py-1 border outline-none" />
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              Set Price...
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                              SKU
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            filtered.map((x, index) => (
                              <tr onClick={() => set_PRODUCT_ID(x["_id"])} key={x["_id"]} className={`hover:bg-blue-200 cursor-pointer duration-300 border-b ${!Boolean(index % 2) ? "bg-gray-100" : "bg-white"}`}>
                                {/* <td className="px-6 w-28 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                  {x["level"]}
                                </td> */}
                                <td className="text-sm text-gray-900 flex flex-col px-6 py-4 whitespace-nowrap">
                                  <h3 className="font-bold">
                                    {x["title"]}
                                  </h3>
                                  <p className="text-xs truncate w-96">
                                    {x["description"]}
                                  </p>
                                </td>
                                <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                                  <p>
                                    ${x["price"]}
                                  </p>
                                </td>
                                <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                                  <p>
                                    {x["SKU"]}
                                  </p>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        }
      </div>
    </div>
  )
}

export default Product