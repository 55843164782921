import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';

function Navbar() {
  
  const asPath = String(window.location.href).split("/").pop();

  const [ open, setOpen ] = useState(false);

  useEffect(() => {

    if(!localStorage.getItem("token")) return window.location.href = "/login"

    axios.interceptors.request.use(function (config) {
        const token = localStorage.getItem("token");
        config.headers.Authorization = "Bearer " + token;
        
        return config;
    });


  }, []);

  return (
   
    <div className="bg-[#1C2536] bg-svg-kobi text-gray-100 top-0 left-0 w-full lg:flex justify-between flex-col lg:h-screen  lg:w-[19rem]">
      <div className="hidden lg:block">
        <div className="px-4 py-2 mb-2">
          <div className="mt-4 rounded-xl bg-[#252E3E] px-4 py-4 flex justify-start items-center shadow">
            <img src="http://i.imgur.com/m5PA5hX.png" className='w-10 h-10 mr-4' />
            <div>
              <h3 className="font-semibold">{localStorage.getItem("username")}</h3>
              <p className="text-sm text-gray-400">Admin Dashboard</p>
            </div>
          </div>
        </div>
        <hr className="border-1 border-[#424d63]" />

        <div className="p-4">
          <Link to="/">
            <div className={`my-1 ${asPath.replace("/", "") === "" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Overview
              </h3>
            </div>
          </Link>
          <Link to="/report">
            <div className={`my-1 ${asPath.replace("/", "") === "report" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "report" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path fillRule="evenodd" d="M2.25 13.5a8.25 8.25 0 0 1 8.25-8.25.75.75 0 0 1 .75.75v6.75H18a.75.75 0 0 1 .75.75 8.25 8.25 0 0 1-16.5 0Z" clipRule="evenodd" />
                <path fillRule="evenodd" d="M12.75 3a.75.75 0 0 1 .75-.75 8.25 8.25 0 0 1 8.25 8.25.75.75 0 0 1-.75.75h-7.5a.75.75 0 0 1-.75-.75V3Z" clipRule="evenodd" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Report
              </h3>
            </div>
          </Link>
          <Link to="/online">
            <div className={`my-1 ${asPath.replace("/", "") === "online" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "online" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Online Orders
              </h3>
            </div>
          </Link>
          <Link to="/install">
            <div className={`my-1 ${asPath.replace("/", "") === "install" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "install" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path fillRule="evenodd" d="M12 6.75a5.25 5.25 0 016.775-5.025.75.75 0 01.313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 011.248.313 5.25 5.25 0 01-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 112.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0112 6.75zM4.117 19.125a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z" clipRule="evenodd" />
                <path d="M10.076 8.64l-2.201-2.2V4.874a.75.75 0 00-.364-.643l-3.75-2.25a.75.75 0 00-.916.113l-.75.75a.75.75 0 00-.113.916l2.25 3.75a.75.75 0 00.643.364h1.564l2.062 2.062 1.575-1.297z" />
                <path fillRule="evenodd" d="M12.556 17.329l4.183 4.182a3.375 3.375 0 004.773-4.773l-3.306-3.305a6.803 6.803 0 01-1.53.043c-.394-.034-.682-.006-.867.042a.589.589 0 00-.167.063l-3.086 3.748zm3.414-1.36a.75.75 0 011.06 0l1.875 1.876a.75.75 0 11-1.06 1.06L15.97 17.03a.75.75 0 010-1.06z" clipRule="evenodd" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Installations
              </h3>
            </div>
          </Link>
          <Link to="/invoice">
            <div className={`my-1 ${asPath.replace("/", "") === "invoice" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "invoice" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clipRule="evenodd" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Invoices
              </h3>
            </div>
          </Link>
          <Link to="/estimate">
            <div className={`my-1 ${asPath.replace("/", "") === "estimate" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "estimate" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Estimates
              </h3>
            </div>
          </Link>
          <Link to="/return">
            <div className={`my-1 ${asPath.replace("/", "") === "return" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "return" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Returns
              </h3>
            </div>
          </Link>
          <Link to="/glass">
            <div className={`hidden my-1 ${asPath.replace("/", "") === "glass" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "glass" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Glass
              </h3>
            </div>
          </Link>
          <Link to="/inventory">
            <div className={`my-1 ${asPath.replace("/", "") === "inventory" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "inventory" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 007.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 004.902-5.652l-1.3-1.299a1.875 1.875 0 00-1.325-.549H5.223z" />
                <path fillRule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 009.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 002.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3zm3-6a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zm8.25-.75a.75.75 0 00-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-5.25a.75.75 0 00-.75-.75h-3z" clipRule="evenodd" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Inventory
              </h3>
            </div>
          </Link>
          <Link to="/customer">
            <div className={`my-1 ${asPath.replace("/", "") === "customer" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "customer" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
              </svg>
              <h3 className="ml-4 text-sm ">
              Customer
              </h3>
            </div>
          </Link>
          {/* <Link to="/product">
            <div className={`my-1 ${asPath.replace("/", "") === "product" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "product" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
              </svg>
              <h3 className="ml-4 text-sm ">
              Product
              </h3>
            </div>
          </Link> */}
          <div onClick={() => window.confirm("Are you sure you want to logout?") ? (localStorage.clear(), window.location.reload() ) : ""} className="text-gray-400 my-1 font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-gray-400 w-5 h-5">
              <path d="M10.375 2.25a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25zM10.375 12a7.125 7.125 0 00-7.124 7.247.75.75 0 00.363.63 13.067 13.067 0 006.761 1.873c2.472 0 4.786-.684 6.76-1.873a.75.75 0 00.364-.63l.001-.12v-.002A7.125 7.125 0 0010.375 12zM16 9.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6z" />
            </svg>
            <h3 className="ml-4 text-sm ">
              Logout
            </h3>
          </div>
        </div>
      </div>
      <div className="lg:hidden flex justify-between items-center h-16 p-2">
        <img src="http://i.imgur.com/m5PA5hX.png" className='w-10 h-10 mr-4' />
        <div>
          <svg onClick={() => setOpen(!open)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" cursor-pointer w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
      </div>
      {
        open
        ?
        <div className="w-[50vw] h-screen fixed top-0 left-0 bg-[#1C2536] z-10">
        <div className="px-4 py-2 mb-2">
          <div className="mt-4 rounded-xl bg-[#252E3E] px-4 py-4 flex justify-start items-center shadow">
            <img src="http://i.imgur.com/m5PA5hX.png" className='w-10 h-10 mr-4' />
            <div>
              <h3 className="font-semibold">{localStorage.getItem("username")}</h3>
              <p className="text-sm text-gray-400">Admin Dashboard</p>
            </div>
          </div>
        </div>
        <hr className="border-1 border-[#424d63]" />

        <div className="p-4">
          <Link to="/">
            <div className={`my-1 ${asPath.replace("/", "") === "" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Overview
              </h3>
            </div>
          </Link>
          <Link to="/report">
            <div className={`my-1 ${asPath.replace("/", "") === "report" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "report" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path fillRule="evenodd" d="M2.25 13.5a8.25 8.25 0 0 1 8.25-8.25.75.75 0 0 1 .75.75v6.75H18a.75.75 0 0 1 .75.75 8.25 8.25 0 0 1-16.5 0Z" clipRule="evenodd" />
                <path fillRule="evenodd" d="M12.75 3a.75.75 0 0 1 .75-.75 8.25 8.25 0 0 1 8.25 8.25.75.75 0 0 1-.75.75h-7.5a.75.75 0 0 1-.75-.75V3Z" clipRule="evenodd" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Report
              </h3>
            </div>
          </Link>
          <Link to="/online">
            <div className={`my-1 ${asPath.replace("/", "") === "online" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "online" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Online Orders
              </h3>
            </div>
          </Link>
          <Link to="/install">
            <div className={`my-1 ${asPath.replace("/", "") === "install" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "install" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path fillRule="evenodd" d="M12 6.75a5.25 5.25 0 016.775-5.025.75.75 0 01.313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 011.248.313 5.25 5.25 0 01-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 112.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0112 6.75zM4.117 19.125a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z" clipRule="evenodd" />
                <path d="M10.076 8.64l-2.201-2.2V4.874a.75.75 0 00-.364-.643l-3.75-2.25a.75.75 0 00-.916.113l-.75.75a.75.75 0 00-.113.916l2.25 3.75a.75.75 0 00.643.364h1.564l2.062 2.062 1.575-1.297z" />
                <path fillRule="evenodd" d="M12.556 17.329l4.183 4.182a3.375 3.375 0 004.773-4.773l-3.306-3.305a6.803 6.803 0 01-1.53.043c-.394-.034-.682-.006-.867.042a.589.589 0 00-.167.063l-3.086 3.748zm3.414-1.36a.75.75 0 011.06 0l1.875 1.876a.75.75 0 11-1.06 1.06L15.97 17.03a.75.75 0 010-1.06z" clipRule="evenodd" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Installations
              </h3>
            </div>
          </Link>
          <Link to="/invoice">
            <div className={`my-1 ${asPath.replace("/", "") === "invoice" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "invoice" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clipRule="evenodd" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Invoices
              </h3>
            </div>
          </Link>
          <Link to="/estimate">
            <div className={`my-1 ${asPath.replace("/", "") === "estimate" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "estimate" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Estimates
              </h3>
            </div>
          </Link>
          <Link to="/glass">
            <div className={`hidden my-1 ${asPath.replace("/", "") === "glass" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "glass" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Glass
              </h3>
            </div>
          </Link>
          <Link to="/inventory">
            <div className={`my-1 ${asPath.replace("/", "") === "inventory" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${asPath.replace("/", "") === "inventory" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 007.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 004.902-5.652l-1.3-1.299a1.875 1.875 0 00-1.325-.549H5.223z" />
                <path fillRule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 009.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 002.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3zm3-6a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zm8.25-.75a.75.75 0 00-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-5.25a.75.75 0 00-.75-.75h-3z" clipRule="evenodd" />
              </svg>
              <h3 className="ml-4 text-sm ">
                Inventory
              </h3>
            </div>
          </Link>
          <Link to="/customer">
            <div className={`my-1 ${asPath.replace("/", "") === "customer" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "customer" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
              </svg>
              <h3 className="ml-4 text-sm ">
              Customer
              </h3>
            </div>
          </Link>
          {/* <Link to="/product">
            <div className={`my-1 ${asPath.replace("/", "") === "product" ? "bg-[#252E3E] text-gray-100" : "text-gray-400"} font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${asPath.replace("/", "") === "product" ? "text-blue-600" : "text-gray-400"}  w-5 h-5`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
              </svg>
              <h3 className="ml-4 text-sm ">
              Product
              </h3>
            </div>
          </Link> */}
          <div onClick={() => window.confirm("Are you sure you want to logout?") ? (localStorage.clear(), window.location.reload() ) : ""} className="text-gray-400 my-1 font-bold flex justify-start items-center py-2 px-4 rounded-xl shadow cursor-pointer hover:bg-[#252E3E]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-gray-400 w-5 h-5">
              <path d="M10.375 2.25a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25zM10.375 12a7.125 7.125 0 00-7.124 7.247.75.75 0 00.363.63 13.067 13.067 0 006.761 1.873c2.472 0 4.786-.684 6.76-1.873a.75.75 0 00.364-.63l.001-.12v-.002A7.125 7.125 0 0010.375 12zM16 9.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6z" />
            </svg>
            <h3 className="ml-4 text-sm ">
              Logout
            </h3>
          </div>
        </div>
      </div>
      :
      ""
      }
  </div>
  )
}

export default Navbar