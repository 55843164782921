import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Textarea from 'react-expanding-textarea'
import LoadingIcon from './LoadingIcon';

function EditProduct({fetchProducts, PRODUCT_ID, set_PRODUCT_ID}) {

  const [ active, setActive ] = useState(true);
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true)

    const res = await axios.post("https://backend.azupin.glass/product/edit", data);

    if(res){
      setLoading(false);
      setTimeout(() => {
        fetchProducts();
      }, 1000);
    }
  }

  const fetchData = async () => {

    const RESULT = await axios.get("https://backend.azupin.glass/product/" + PRODUCT_ID);
  
    setData(RESULT["data"]);

    setLoading(false);

  }

  useEffect(() => {

    fetchData();

    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = "Bearer " + token;
      
      return config;
    });

  }, [])

  return (
    <div className={`z-10 fixed top-0 right-0 h-screen w-screen`}>
      <div className={`${active ? "slide-in-right" : "slide-out-right"} bg-white w-1/2 h-full p-10 z-[5] absolute top-0 right-0`}>


        {
          loading
          ?
          <LoadingIcon />
          :
          <form onSubmit={handleSubmit} className="flex flex-col">
            
            <h3 className="text-xl font-bold uppercase mb-4">You're editing - {data["SKU"]}</h3>
            <div className="w-full flex mb-2">
              <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
                <label for="title" className="text-xs">Title</label>
                <input onChange={(e) => setData({...data, title: e.target.value})} required value={data["title"]} className="w-full px-2 py-1 outline-none border" placeholder='Title' id="title" name="title" />
              </div>
              <div className="flex flex-col justify-start items-start">
                <label for="price" className="text-xs">Price</label>
                <input onChange={(e) => setData({...data, price: e.target.value})} required value={data["price"]} className="flex-1 px-2 py-1 outline-none border" placeholder='Price' name="price" id="price" type="float" />
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-full mb-2">
              <label for="description" className="text-xs">Description</label>
              <Textarea placeholder='Description' value={data["description"]} onChange={(e) => setData({...data, description: e.target.value})} className="w-full text-xs h-auto outline-none border p-2" />
            </div>
            <div className="flex">
              <div className="flex flex-col mr-2 justify-start items-start w-full mb-2">
                <label for="image" className="text-xs">Image</label>
                <input value={data["image"]} className="px-2 py-1 outline-none border w-full" placeholder='Image' name="image" />
              </div>
              <div className="flex flex-col justify-start items-start w-full mb-2">
                <label for="sku" className="text-xs">SKU</label>
                <input value={data["SKU"]} onChange={(e) => setData({...data, SKU: e.target.value})} className="px-2 py-1 outline-none border w-full" placeholder='SKU' name="sku" />
              </div>
            </div>

            <button className="px-4 py-2 bg-blue-400">Submit</button>

          </form>
        }

      </div>
      <div onClick={() => {setActive(false); setTimeout(() => {set_PRODUCT_ID(false)}, 500)}} className="bg-black w-full h-full bg-opacity-50 absolute top-0 cursor-pointer left-0"></div>
    </div>
  )
}

export default EditProduct