import React, {useState, useEffect} from 'react'
import Textarea from 'react-expanding-textarea'
import axios from 'axios';
import BarcodeReader from 'react-barcode-reader'

function AddInventory({fetchData}) {

  const [ open, setOpen ] = useState(true);
  const [ stock, setStock ] = useState(localStorage.getItem("addStock") ? JSON.parse(localStorage.getItem("addStock")) : []);
  const [ message, setMessage ] = useState("");

  const handleSave = async () => {

    const RESULT = await axios.post("https://backend.azupin.glass/product", stock)

    if(RESULT){
      fetchData();
      // alert("It has been modified.")
    }
  }

  const deleteStock = (x, a) => {

    let copy = stock.filter(b => b["barcode"] !== x["barcode"]);

    setStock(copy)
    localStorage.setItem("addStock", JSON.stringify(copy))

  }

  const callbac = (a) => {
    
    if(stock.filter(e => e?.barcode === a).length > 0){

      let copy = [...stock];

      copy[stock.findIndex(e => e?.barcode === a)]["stock"] += 1;

      setStock(copy)
      
      localStorage.setItem("addStock", JSON.stringify(copy))

    }else{
    
      let copy = [...stock];

      copy.push({
        barcode: a,
        stock: 1
      });

      setStock(copy)

      localStorage.setItem("addStock", JSON.stringify(copy))
    }

  }

  useEffect(() => {

    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = "Bearer " + token;
      
      return config;
    });

  }, [])

  return (
    <div className={`border-b grid lg:grid-cols-8 text-black py-2 font-semibold duration-200 border mt-2 mb-20 rounded-lg ${open ? "border rounded-lg overflow-hidden" : ""} `}>
        <BarcodeReader
          onError={callbac}
          onScan={callbac}
          />
      {
        open
        ?
        <div className="w-full relative h-auto pb-20 pt-6 col-span-8 px-10 gap-10 flex flex-col">
          
          <h3 className="text-lg font-bold">
            Add Inventory
          </h3>

          <div className="grid lg:grid-cols-4 ">

            <div className="justify-start items-center my-1 border shadow p-2">
              Title
            </div>
            <div className="justify-start items-center my-1 border shadow p-2">
              Qty
            </div>
            <div className="justify-start items-center my-1 border shadow p-2">
              Image
            </div>
            <div className="justify-start items-center my-1 border shadow p-2 text-right">
              Actions
            </div>
            
            {
              stock.map((x, index) => (
                <div key={index} className="grid lg:grid-cols-4 col-span-4 justify-start items-center my-1 border shadow p-2">
                  <div className="flex">
                    {x["barcode"]}
                  </div>
                  <div className="flex">
                    {x["stock"]}
                  </div>
                  <div className="flex">
                    <img src={`https://backend.azupin.glass/productimage/` + x["barcode"]} className="w-10 h-10"  />
                  </div>
                  <div className="flex justify-end">
                    <svg onClick={() => deleteStock(x, index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                  </div>
                </div>
              ))  
            }

          </div>


          <div className="w-full border-t absolute bottom-0 left-0 h-16 px-10 flex justify-between items-center">
            <div>
            </div>
            <div>
              <button onClick={handleSave} className="bg-green-400 px-4 py-2 text-white font-semibold rounded-lg shadow tranform hover:scale-105 duration-200">
                Add
              </button>
            </div>
          </div>

        </div>
        :
        ""
      }

    </div>
  )
}

export default AddInventory