import React, {useState, useEffect} from 'react'
import Textarea from 'react-expanding-textarea'
import axios from 'axios';

function CreateInventory({fetchData}) {

  const [ open, setOpen ] = useState(true);
  const [ copyOfData, setCopyOfData ] = useState(
    {
      "_id": "",
      "title": "",
      "description": "",
      "key": "",
      "stock": "",
      "__v": 1,
      "type": "",
      "image": "",
      "barcode": "",
      "site": "",
      "reserved": "",
      "shopPricing": "",
      "contractorPricing": "",
      "installerPricing": ""
    });

  const handleSave = async () => {

    const RESULT = await axios.post("https://backend.azupin.glass/product", copyOfData)

    if(RESULT){
      fetchData();
      // alert("It has been modified.")
    }
  }

  useEffect(() => {

    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = "Bearer " + token;
      
      return config;
    });

  }, [])


  // {
  //   "_id": "635ff7bd28c2a95848bcfffa",
  //   "title": "SS05 - 60x76 A ",
  //   "description": "SS05 Sliding Door",
  //   "key": "SS056076A",
  //   "stock": "2",
  //   "timestamp": "2022-10-31T16:28:45.675Z",
  //   "__v": 1,
  //   "type": "Glass",
  //   "image": "https://cdn.shopify.com/s/files/1/0571/4889/9443/products/SS05_5b9bad04-d58b-40f2-9309-a442020dec32.jpg?v=1673666159&width=1946",
  //   "barcode": "CAT7087",
  //   "site": "1",
  //   "reserved": "1",
  //   "shopPricing": "32",
  //   "contractorPricing": "213",
  //   "installerPricing": "213"
  // }

  return (
    <div className={`border-b grid lg:grid-cols-8 text-black py-2 font-semibold duration-200 border mt-2 mb-20 rounded-lg ${open ? "border rounded-lg overflow-hidden" : ""} `}>

      {
        open
        ?
        <div className="w-full relative h-auto col-span-8 pb-20 pt-6 px-10 grid lg:grid-cols-9 gap-10">
          
          <div className="col-span-2">
            <div className="border rounded-xl overflow-hidden shadow w-60 h-60 mx-auto">
              <img src={copyOfData["image"]} className='w-60 h-60 object-contain' />
            </div>
          </div>

          <div className="col-span-3">
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Title</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, title: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder='Catalyst - 60"W x 76"H - B ( Towel Bar )' value={copyOfData["title"]} />
            </div>
            <div className="flex flex-row w-full justify-between items-center mb-6">
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">SKU</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, key: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CATALYST-60X76-B" value={copyOfData["key"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Barcode</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, barcode: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CAT6076B" value={copyOfData["barcode"]} />
              </div>
            </div>
            <div className="flex flex-row w-full justify-start items-center mb-6 gap-6">
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Stock</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, stock: e.target.value})}  className="outline-none border-b w-20 border-gray-500 focus:border-blue-700 duration-300 py-1" placeholder="0" value={copyOfData["stock"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Site</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, site: e.target.value})} className="outline-none border-b w-20 border-gray-500 focus:border-blue-700 duration-300 py-1" placeholder="0" value={copyOfData["site"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Reserved</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, reserved: e.target.value})} className="outline-none border-b w-20 border-gray-500 focus:border-blue-700 duration-300 py-1" placeholder="0" value={copyOfData["reserved"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Type</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, type: e.target.value})} className="outline-none border-b w-full border-gray-500 focus:border-blue-700 duration-300 py-1" placeholder="0" value={copyOfData["type"]} />
              </div>
            </div>  
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Image</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, image: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder='URL' value={copyOfData["image"]} />
            </div>
          </div>

          <div className="col-span-1">
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Contractor</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, contractorPricing: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="100" value={copyOfData["contractorPricing"]} />
            </div>
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Glass Installer</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, installerPricing: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="100" value={copyOfData["installerPricing"]} />
            </div>
            <div className="flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Glass Shop</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, shopPricing: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="100" value={copyOfData["shopPricing"]} />
            </div>
          </div>

          <div className="col-span-3">
            <div className="flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Description</label>
              <Textarea placeholder='' value={copyOfData["description"]} onChange={(e) => setCopyOfData({...copyOfData, description: e.target.value})} className="border-b border-gray-500 focus:border-blue-700 py-2 w-full text-xs h-auto outline-none" />
            </div>
          </div>

          <div className="w-full border-t absolute bottom-0 left-0 h-16 px-10 flex justify-between items-center">
            <div>
            </div>
            <div>
              <button onClick={handleSave} className="bg-green-400 px-4 py-2 text-white font-semibold rounded-lg shadow tranform hover:scale-105 duration-200">
                Save
              </button>
            </div>
          </div>

        </div>
        :
        ""
      }

    </div>
  )
}

export default CreateInventory