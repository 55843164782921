import React, { useState, useEffect } from 'react'
import axios from 'axios';

function UpdateGlass({fetchData}) {

  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval = null;

    if (loading) {
      interval = setInterval(() => {
        setTimer(timer => timer + 1000);  
      }, 1000);
    } else if (!loading && interval) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [loading]);

  const commenceFetch = async () => {
    setLoading(true);

    await axios.get("https://backend.azupin.glass/updateLibertyGmail");

    setLoading(false)
    fetchData();

    alert("Done Updating...");

  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between">
        <h4 className="font-semibold text-xl">Update Glass</h4> 
        {
          loading
          ?
          <button onClick={() => setLoading(false)} className="bg-black rounded-lg shadow py-1 px-2 text-white text-xs hover:shadow-2xl hover:text-black hover:bg-white hover:ring-1 duration-300">Stop</button>
          :
          <button onClick={() => commenceFetch()} className="bg-black rounded-lg shadow py-1 px-2 text-white text-xs hover:shadow-2xl hover:text-black hover:bg-white hover:ring-1 duration-300">Update</button>
        }
      </div>
      <h4 className="mt-4 text-lg">Timer: { timer / 1000 }s</h4>
      <div>
        {
          loading
          ?
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 animate-spin mx-auto mt-10">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
          :
          ""
        }
      </div>
    </div>
  )
}

export default UpdateGlass