import React, {useState, useEffect} from 'react'
import Textarea from 'react-expanding-textarea'
import axios from 'axios';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';

function EachGlass({x, index, fetchData}) {

  const [ open, setOpen ] = useState(false);
  const [ copyOfData, setCopyOfData ] = useState({...x});

  const handleSave = async () => {

    const RESULT = await axios.post("https://backend.azupin.glass/glass/edit", copyOfData)

    console.log(RESULT)


    if(RESULT){
      fetchData();
      // alert("It has been modified.")
    }
  }

  const handleDelete = async () => {

    const RESULT = await axios.delete("https://backend.azupin.glass/glass/" + copyOfData["_id"]);

    if(RESULT){
      fetchData();
    }

  }

  useEffect(() => {

    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = "Bearer " + token;
      
      return config;
    });

  }, [])  

  return (
    <div className={`text-black font-semibold duration-200 rounded-lg ${open ? "overflow-hidden border-b shadow-lg" : ""} `}>
      <div onClick={() => setOpen(!open)} key={x["_id"]} className={`hover:bg-blue-200 cursor-pointer text-xs grid lg:grid-cols-11 items-center duration-300 border-b ${!Boolean(index % 2) ? "bg-gray-100" : "bg-white"}`}>
        <div className="flex-1 px-2 py-1 whitespace-nowrap font-medium text-gray-900">
            {moment(x["timestamp"]).format("L")}
        </div>
        <div className="flex-1 text-gray-900 px-2 py-1 whitespace-nowrap">
        <h3 className="font-bold">
            {x["customerName"]?.length <= 24 ? x["customerName"] : x["customerName"]?.substr(0, 24) + "..."}
        </h3>
        </div>
        <div className="flex-1 text-gray-900 px-2 py-1 whitespace-nowrap">
        <h3 className="font-bold">
            {
              x["title"]
              ?
              x["title"]?.length <= 12 ? x["title"] : x["title"]?.substr(0, 12) + "..."
              :
              ""
            }
        </h3>
        <p>{x["orderNumber"] ? "#" + x["orderNumber"] : ""}</p>
        </div>
        <div className="flex-1 text-gray-900 font-normal px-2 py-1 whitespace-nowrap">
        {x["quantity"] ? x["quantity"] : "0"}
        </div>
        <div className="flex-1 text-gray-900 font-normal px-2 py-1 whitespace-nowrap">
        {x["paid"]}
        </div>
        <div className="flex-1 text-gray-900 font-normal px-2 py-1 whitespace-nowrap">
        {x["cost"]}
        </div>
        <div className="flex-1 text-gray-900 font-normal px-2 py-1 whitespace-nowrap">
        {x["customerPaid"]}
        </div>
        <div className="flex-1 text-gray-900 px-2 py-1 whitespace-nowrap">
        <h3 className="text-xs font-bold">
            {x["from"].length <= 17 ? x["from"] : x["from"].substr(0, 17) + "..."}
        </h3>
        <p>
            Ref: {x["invoiceNumber"] ? x["invoiceNumber"] : "N/A"}
        </p>
        </div>
        <div className="flex-1 text-gray-900 font-bold px-2 py-1 whitespace-nowrap">
        {x["dateETA"].length > 2 ? (moment(x["dateETA"]).format("L")) : ""}
        </div>
        <div className="flex-1 text-gray-900 font-bold px-2 py-1 whitespace-nowrap">
        {x["dateDelivered"] ? (moment(x["dateDelivered"]).format("L")) : ""}
        </div>
        <div className="flex-1 text-gray-900 font-bold px-2 py-1 whitespace-nowrap">
        {x["datePickedUp"] ? (moment(x["datePickedUp"]).format("L")) : ""}
        </div>
    </div>

      {
        open
        ?
        <div className="w-full relative h-auto col-span-8 border-t pb-20 pt-6 px-10 grid lg:grid-cols-7 gap-10">
          
          <div className="col-span-3">
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Title / Customer Name</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, title: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder='Title' value={copyOfData["title"]} />
            </div>
            <div className="flex flex-row w-full justify-between items-center mb-6 gap-2">
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">PO Number</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, customerName: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="PO Number" value={copyOfData["customerName"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">From</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, from: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="Liberty" value={copyOfData["from"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Order Number</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, orderNumber: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="5546" value={copyOfData["orderNumber"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Invoice Number</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, invoiceNumber: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="4241535" value={copyOfData["invoiceNumber"]} />
              </div>
            </div>
            <div className="flex flex-row w-full justify-between items-center mb-6 gap-4">
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">ETA</label>
                <input type="date" className="bg-white border mb-2 outline-none z-10" onChange={(e) => {setCopyOfData({...copyOfData, dateETA: String(e.target.value)})}} value={copyOfData["dateETA"].length > 2 ? new Date(copyOfData["dateETA"]).toISOString().split("T")[0] : ""} />
                {/* <input onChange={(e) => setCopyOfData({...copyOfData, key: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CATALYST-60X76-B" value={copyOfData["customerName"]} /> */}
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Delivered</label>
                <input type="date" className="bg-white border mb-2 outline-none z-10" onChange={(e) => {setCopyOfData({...copyOfData, dateDelivered: String(e.target.value)})}} value={copyOfData["dateDelivered"] ? new Date(copyOfData["dateDelivered"]).toISOString().split("T")[0] : ""} />
                {/* <input onChange={(e) => setCopyOfData({...copyOfData, key: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CATALYST-60X76-B" value={copyOfData["customerName"]} /> */}
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Picked Up</label>
                <input type="date" className="bg-white border mb-2 outline-none z-10" onChange={(e) => {setCopyOfData({...copyOfData, datePickedUp: String(e.target.value)})}} value={copyOfData["datePickedUp"] ? new Date(copyOfData["datePickedUp"]).toISOString().split("T")[0] : ""} />
                {/* <input onChange={(e) => setCopyOfData({...copyOfData, barcode: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CAT6076B" value={copyOfData["invoiceNumber"]} /> */}
              </div>
            </div>
          </div>

          <div className="col-span-1">
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Paid / Check No.</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, paid: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="#1198" value={copyOfData["paid"]} />
            </div>
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Cost</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, cost: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="$100" value={copyOfData["cost"]} />
            </div>
            <div className="flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Sold</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, customerPaid: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="$125" value={copyOfData["customerPaid"]} />
            </div>
          </div>

          <div className="col-span-3">
            <div className="flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Notes</label>
              <Textarea placeholder='' value={copyOfData["description"]} onChange={(e) => setCopyOfData({...copyOfData, description: e.target.value})} className="border-b border-gray-500 focus:border-blue-700 py-2 w-full text-xs h-auto outline-none" />
            </div>
            <div className="mt-2 flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Description</label>
              <Textarea placeholder='' value={copyOfData["stock"]?.join("\n")} onChange={(e) => setCopyOfData({...copyOfData, stock: e.target.value.split("\n")})} className="border-b border-gray-500 focus:border-blue-700 py-2 w-full text-xs h-auto outline-none" />
            </div>
          </div>

          <div className="w-full border-t absolute bottom-0 left-0 h-16 px-10 flex justify-between items-center">
            <div>
              <button onClick={() => window.confirm("Are you sure") ? handleDelete() : console.log("Canceled")} className="bg-red-400 px-4 py-2 text-white font-semibold rounded-lg shadow tranform hover:scale-105 duration-200">
                Delete
              </button>
            </div>
            <div>
              <button onClick={handleSave} className="bg-green-400 px-4 py-2 text-white font-semibold rounded-lg shadow tranform hover:scale-105 duration-200">
                Save
              </button>
            </div>
          </div>

        </div>
        :
        ""
      }

    </div>
  )
}

export default EachGlass