import React, {useState, useEffect} from 'react'
import Textarea from 'react-expanding-textarea'
import axios from 'axios';
import moment from 'moment';

function EachInventory({x, fetchData}) {

  const [ open, setOpen ] = useState(false);
  const [ copyOfData, setCopyOfData ] = useState({...x});

  const handleSave = async () => {

    const RESULT = await axios.post("https://backend.azupin.glass/product/edit", copyOfData)

    if(RESULT){
      fetchData();
      // alert("It has been modified.")
    }
  }

  const handleDelete = async () => {

    const RESULT = await axios.delete("https://backend.azupin.glass/product/" + copyOfData["_id"]);

    if(RESULT){
      fetchData();
    }

  }

  useEffect(() => {

    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = "Bearer " + token;
      
      return config;
    });

  }, [])  

  return (
    <div className={`border-b grid lg:grid-cols-8 text-black py-2 font-semibold duration-200 border my-2 rounded-lg ${open ? "border rounded-lg overflow-hidden shadow-lg" : ""} `}>
      <div className={`grid grid-cols-8 col-span-8 cursor-pointer justify-center py-2`} onClick={() => setOpen(!open)}>
        
        
        <div className={`flex justify-start lg:col-auto col-span-2 items-center flex-row pl-4 lg:pl-2 `}>
          {x["image"] ? 
          <img loading="lazy" src={x["image"]} className={`w-10 h-10 rounded overflow-hidden `} /> 
          : 
          <div className="w-10 h-10 bg-blue-100 rounded"></div>
          }
        </div>
        <div className="lg:col-span-4 col-span-6">
          <div className="flex flex-col">
            <p className="text-xs font-semibold text-gray-500">
            {x["key"]}
            </p>
            <h3 className="lg:text-ellipsis truncate">
            {x["title"]}
            </h3>
          </div>
        </div>
        <div className="lg:flex hidden justify-start items-center">
          {x["type"]}
        </div>
        <div className="flex justify-start lg:col-auto col-span-4 items-center pr-2 ">
          <span className="lg:hidden block ml-4 mr-2">Stock: </span>
          {x["stock"]}
        </div>
        <div className="flex justify-end lg:justify-start items-center pr-2 lg:col-auto col-span-4 ">
          <div className="flex flex-row jusitify-start items-center">
            <div className="text-xs bg-gray-100 hover:bg-green-300 duration-200 cursor-pointer flex flex-col m-1 p-1">
              <h3 className="text-[0.5rem] leading-[0.4rem]">Contractor</h3>
              ${x["contractorPricing"] ? x["contractorPricing"] : "0"}
            </div>
            <div className="text-xs bg-gray-100 hover:bg-green-300 duration-200 cursor-pointer flex flex-col m-1 p-1">
              <h3 className="text-[0.5rem] leading-[0.4rem]">Installer</h3>
              ${x["installerPricing"] ? x["installerPricing"] : "0"}
            </div>
            <div className="text-xs bg-gray-100 hover:bg-green-300 duration-200 cursor-pointer flex flex-col m-1 p-1">
              <h3 className="text-[0.5rem] leading-[0.4rem]">Shop</h3>
              ${x["shopPricing"] ? x["shopPricing"] : "0"}
            </div>
          </div>
        </div>
      </div>

      {
        open
        ?
        <div className="w-full relative h-auto col-span-8 border-t pb-20 pt-6 px-10 grid lg:grid-cols-9 gap-10">
          
          <div className="col-span-2">
            <div className="mx-auto">
              <div className="border rounded-xl overflow-hidden shadow w-60 h-60 mx-auto">
                <img src={x["image"]} className='w-60 h-60 object-contain' />
              </div>
              <div className="w-56 mx-auto mt-2">
                <img onClick={() => window.open(`https://barcode.tec-it.com/barcode.ashx?data=${x["barcode"]}&translate-esc=on&dpi=300`, "_blank")} src={`https://barcode.tec-it.com/barcode.ashx?data=${x["barcode"]}&translate-esc=on`} className="cursor-pointer h-full" />
              </div>
            </div>
          </div>

          <div className="col-span-3">
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Title</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, title: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder='Catalyst - 60"W x 76"H - B ( Towel Bar )' value={copyOfData["title"]} />
            </div>
            <div className="flex flex-row w-full justify-between items-center mb-6">
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">SKU</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, key: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CATALYST-60X76-B" value={copyOfData["key"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Barcode</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, barcode: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="CAT6076B" value={copyOfData["barcode"]} />
              </div>
            </div>
            <div className="flex flex-row w-full justify-start items-center mb-6 gap-6">
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Stock</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, stock: e.target.value})}  className="outline-none border-b w-20 border-gray-500 focus:border-blue-700 duration-300 py-1" placeholder="0" value={copyOfData["stock"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Site</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, site: e.target.value})} className="outline-none border-b w-20 border-gray-500 focus:border-blue-700 duration-300 py-1" placeholder="0" value={copyOfData["site"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Reserved</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, reserved: e.target.value})} className="outline-none border-b w-20 border-gray-500 focus:border-blue-700 duration-300 py-1" placeholder="0" value={copyOfData["reserved"]} />
              </div>
              <div className="flex justify-start items-start flex-col">
                <label className="text-sm text-gray-500">Type</label>
                <input onChange={(e) => setCopyOfData({...copyOfData, type: e.target.value})} className="outline-none border-b w-full border-gray-500 focus:border-blue-700 duration-300 py-1" placeholder="0" value={copyOfData["type"]} />
              </div>
            </div>  
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Image</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, image: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder='URL' value={copyOfData["image"]} />
            </div>
          </div>

          <div className="col-span-1">
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Contractor</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, contractorPricing: e.target.value})}  className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="100" value={copyOfData["contractorPricing"]} />
            </div>
            <div className="flex justify-start items-start flex-col mb-6">
              <label className="text-sm text-gray-500">Glass Installer</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, installerPricing: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="100" value={copyOfData["installerPricing"]} />
            </div>
            <div className="flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Glass Shop</label>
              <input onChange={(e) => setCopyOfData({...copyOfData, shopPricing: e.target.value})} className="outline-none border-b border-gray-500 focus:border-blue-700 duration-300 w-full py-1" placeholder="100" value={copyOfData["shopPricing"]} />
            </div>
          </div>

          <div className="col-span-3">
            <div className="flex justify-start items-start flex-col">
              <label className="text-sm text-gray-500">Description</label>
              <Textarea placeholder='' value={copyOfData["description"]} onChange={(e) => setCopyOfData({...copyOfData, description: e.target.value})} className="border-b border-gray-500 focus:border-blue-700 py-2 w-full text-xs h-auto outline-none" />
            </div>
          </div>

          <div className="w-full border-t absolute bottom-0 left-0 h-16 px-10 flex justify-between items-center">
            <div>
              <button onClick={() => window.confirm("Are you sure") ? handleDelete() : console.log("Canceled")} className="bg-red-400 px-4 py-2 text-white font-semibold rounded-lg shadow tranform hover:scale-105 duration-200">
                Delete
              </button>
            </div>
            <div>
              <button onClick={handleSave} className="bg-green-400 px-4 py-2 text-white font-semibold rounded-lg shadow tranform hover:scale-105 duration-200">
                Save
              </button>
            </div>
          </div>

        </div>
        :
        ""
      }

    </div>
  )
}

export default EachInventory