import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios';
import Textarea from 'react-expanding-textarea'
import Select from 'react-select'
import FilePreview from './FilePreview';
import FileBase64 from 'react-file-base64';


function OpenOnlineOrder({fetchData, loadedOrder, setLoadedOrder}) { 

  const [ createProduct, setCreateProduct ] = useState(false);
  const [ active, setActive ] = useState(true);
  const [ data, setData ] = useState({});
  const [ showFile, setShowFile ] = useState("");

  const [ product, setProduct ] = useState([]);

  const [ loading, setLoading ] = useState(true);

  const fetchInvoice = async () => {

    const res = await axios.get("https://supplier-backend.azupin.glass/order/" + loadedOrder);
    setData(res["data"])
    setLoading(false)
  }

  const getTotal = () => {
    let total = 0;
    data["products"].forEach(x => {
      total += x["quantity"] * x["rate"]
    })

    return total
  }

  const save = async () => {

    setLoading(true)

    const res = await axios.post("https://supplier-backend.azupin.glass/updateOrder/" + data["_id"], {...data, id: data["_id"], salePrice: data["products"].length === 1 ? Number(data["products"][0]["quantity"] * data["products"][0]["rate"]).toFixed(2) : getTotal()});
    
    if(res["data"]["message"] === "success"){
    setActive(false)
      setTimeout(() => {
        setLoading(false);
        setLoadedOrder(false)
        fetchData();
      }, 500);
    }

  }


  const compressBase64 = async (base64String, options = {
    maxWidthOrHeight: 500,
    quality: 0.2,
  }) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = options.maxWidthOrHeight;
        canvas.height = options.maxWidthOrHeight;
 
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
 
        const compressedBase64 = canvas.toDataURL(
          'image/jpeg',
          options.quality
        );
        resolve(compressedBase64);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = base64String;
      // img.size = (Math.round(base64String.length * 3/4) / 1024).toFixed(2)
    });
  };


  async function reduce_image_file_size(base64Str, MAX_WIDTH = 4000, MAX_HEIGHT = 4000) {
    let resized_base64 = await new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
            let canvas = document.createElement('canvas')
            let width = img.width
            let height = img.height

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width
                    width = MAX_WIDTH
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height
                    height = MAX_HEIGHT
                }
            }
            canvas.width = width
            canvas.height = height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL('image/jpeg', 0.5))
        }
    });
    return {base64: resized_base64, size: (Math.round(resized_base64.length * 3/4) / 1024).toFixed(2) };
}

const getFiles = async (files) => {

  const compressed = await Promise.all(files.map(async (x) => 
    {
      const reduced = x["type"].includes("image") ? await reduce_image_file_size(x["base64"]) : null;
      return {
        ...x,
        base64: x["type"].includes("image") ? reduced["base64"] : x["base64"],
        size: x["type"].includes("image") ? reduced["size"] + " Kb" : x["size"]
      }
    }
    ))

  console.log(compressed)
  // console.log({...data, files: {...data["files"], compressed}})
  setData({...data, files: [...data["files"], ...compressed]})
}

  const editQuantity = (val, index) => {
    let copy = data;

    copy["products"][index]["quantity"] = Number(val);

    setData({...data, products: copy["products"]})

  }

  const changeDescription = (val, index) => {
    let copy = data;

    copy["products"][index]["description"] = val;
    
    setData({...data, products: copy["products"]})
  }

  const deleteInvoice = async () => {

    if(window.confirm("Are you sure you want to delete this invoice?")){
      const res = await axios.delete("https://supplier-backend.azupin.glass/invoice/" + data["_id"]);

      if(res){
        return window.location.reload();
      }
    }

  }

  useEffect(() => {
    fetchInvoice();

  }, [])

  return (
    <div className={`${active ? "slide-in-top" : "slide-out-top"} fixed top-0 left-0 z-10`}>
      <div className='bg-white relative flex justify-center items-center w-screen overflow-y-scroll h-screen top-0 left-0'>
        <div className="flex justify-between items-center absolute top-0 left-0 py-2 px-4 w-full">
          <div className="flex justify-start items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
            <h3 className="font-bold text-xl">
              Invoice #{data["order"]}
            </h3>
          </div>
          <svg onClick={() => {setActive(false); setTimeout(() => {setLoadedOrder(false)}, 500)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-10 h-10 hover:text-red-500 duration-300">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        {
          loading
          ?
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 animate-spin">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
          :
          <div className="w-full h-full">
            {
              showFile
              ?
              <FilePreview setShowFile={setShowFile} url={showFile} />
              :
              ""
            }
            <div className="px-4 pt-14 pb-4 bg-gray-100 flex flex-row justify-between">
              <div className="flex flex-row justify-start items-start">
                <div className="flex flex-col justify-start items-start mr-4">
                  <label for="customer">Customer:</label>
                  <div className="bg-white p-2 border w-60">
                  <h3 className="">{data["customer"]}</h3>
                  </div>
                  <div className="flex flex-col justify-start items-start">
                  <div className="flex flex-col justify-start items-start mb-4">
                    <label for="phone">Phone Number:</label>
                    <div className="flex bg-white p-2 border w-60">
                      {data["customerPhone"] ? data["customerPhone"]["number"] + " ext. " + data["customerPhone"]["ext"] : "N/A"}
                    </div>
                  </div>
                </div>
                  {/* </textarea> */}
                </div>
                <div className="flex flex-col justify-start items-start">
                  <div className="flex flex-col justify-start items-start">
                    <label for="PO">P.O</label>
                    <div className="bg-white p-2 border w-60">
                      {data["PO"]}
                    </div>
                  </div>
                  <label for="shippingAddress">Shipping Address:</label>
                  {/* <textarea id="shippingAddress" name="shippingAddress" className="px-4 py-2 border w-full"> */}
                    <div className="bg-white p-2 border w-60">
                      {data["shippingAddress"]}
                    </div>
                  {/* </textarea> */}
                </div>
                <div className="flex flex-col justify-start items-start ml-4">
                  <div className="flex flex-col justify-start items-start">
                    <label for="PO">Status</label>
                    <div className="bg-white p-2 border w-60">
                      <input value={data["status"]} onChange={(e) => setData({...data, status: e.target.value})} />
                    </div>
                  </div>
                </div>
              </div>
              
              <div>
                <h3 className="uppercase text-xs text-right">BALANCE DUE</h3>
                <h1 className="text-5xl font-bold text-right">${getTotal() + (data["shipping"] ? 300 : 0)}</h1>

                <div className="my-10">

                  <div className="flex flex-col justify-start">
                    <label for="order">Order Number:</label>
                    <div className="bg-white px-4 py-2">
                      {data["order"]}
                    </div>
                    {/* <input onChange={(e) => setData({...data, order: e.target.value})} value={data["order"]} id="order" className="border px-4 py-2 bg-white outline-none" type="text" name="order" /> */}
                  </div>

                </div>
              </div>

            </div>

            <div className="bg-white px-4 py-2">

            <div className="flex flex-col w-full mb-20">
              <div className="overflow-x-auto">
                <div className="py-2 inline-block min-w-full">
                  <div className="overflow-hidden">
                    <table className="min-w-full border-collapse">
                      <thead className="bg-white border-b">
                        <tr>
                          <th scope="col" className="border-b border-r text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            #
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-2 text-left">
                            Title
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Description
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Qty
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Rate
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data["products"].map((x, index) => (
                            <tr key={index} className={`hover:shadow-lg duration-300 border-b ${!Boolean(index % 2) ? "bg-white" : "bg-white"}`}>
                              <td className="border-t border-b border-r px-6 w-10 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                {index + 1}
                              </td>
                              <td className="border font-bold px-6 w-96 py-2 whitespace-nowrap text-sm text-gray-900">
                                {/* <select onChange={(e) => changeProduct(e.target.value, index)} className="w-full outline-none border shadow rounded px-2 py-1">
                                  <option>{x["title"]}</option>
                                  <option>Test</option>
                                </select> */}
                                <h4 className="w-96 truncate">
                                  {x["title"]}
                                </h4>
                              </td>
                              <td className="border text-gray-900 px-6 py-4 whitespace-nowrap">
                                {/* {x["description"]} */}
                                <Textarea disabled placeholder='Description' value={x["description"]} className="w-full text-xs h-auto outline-none" />
                              </td>
                              <td className="border text-lg w-20 text-gray-900 px-6 py-4 whitespace-nowrap">
                                {x["quantity"]}
                                {/* <input min={0} onChange={(e) => editQuantity(e.target.value, index)} value={x["quantity"]} className="w-20 outline-none border p-1" placeholder="Qty" type="number"  /> */}
                              </td>
                              <td className="border text-sm text-gray-900 w-10 font-bold px-6 py-4 whitespace-nowrap">
                                ${x["rate"]}
                              </td>
                              <td className="border w-24 text-sm text-gray-900 font-bold px-6 py-4 whitespace-nowrap">
                                <p className="w-24">
                                  ${Number(x["rate"] * x["quantity"]).toFixed(2)} 
                                </p>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-start">
                <div>
                  {/* <div className="flex flex-row">
                    <button onClick={() => addProduct()} className="px-4 py-2 border shadow rounded-lg border-black text-xs mr-2">Add Line</button>
                    <button onClick={() => setCreateProduct(true)} className="px-4 py-2 border shadow rounded-lg border-black text-xs mr-2">Add Product</button>
                    <button onClick={() => fetchProducts()} className="px-4 py-2 border shadow rounded-lg border-black text-xs mr-2">Fetch Product</button>
                  </div> */}
                  <div className="mt-4">
                    <h3>File on Invoice...</h3>
                    <div className="mt-4">
                      <FileBase64 multiple={true} onDone={(e) => getFiles(e)} />
                    </div>
                    {data["files"].map(x => (
                      <div onClick={() => setShowFile(x["base64"])} className="shadow py-2 px-4 border select-none cursor-pointer">
                        <h3>{x["name"]} - <span className="font-bold">{x["size"]}</span></h3>
                      </div>
                    ))}
                    {/* <textarea onChange={(e) => setData({...data, message: e.target.value})} defaultValue={data["message"]} className="px-4 py-2 border outline-none w-96 h-40"></textarea> */}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-20 gap-y-2">
                  <h3>
                    Subtotal:
                  </h3>
                  <span className="ml-1 font-bold">
                    ${data["products"].length === 1 ? Number(data["products"][0]["quantity"] * data["products"][0]["rate"]).toFixed(2) : getTotal()}
                  </span>
                  <h3>
                    Shipping:
                  </h3>
                  <span className="ml-1 font-bold">
                    ${data["shipping"] ? 300 : 0}
                  </span>
                  <h3>Total</h3>
                  <span className="ml-1 font-bold">
                    ${getTotal() + (data["shipping"] ? 300 : 0)}
                  </span>
                </div>
              </div>
            </div>

            </div>
          </div>
        }

        {/* footer */}
        <div className="bg-gray-500 py-2 px-4 fixed text-sm bottom-0 left-0 w-full h-30 flex justify-between items-center">
          <div className="flex justify-center items-center">
            <button onClick={() => {setActive(false); setTimeout(() => {setLoadedOrder(false)}, 500)}} className="text-white border rounded-full px-6 py-2 hover:bg-white duration-300 hover:text-black">Cancel</button>
          </div>

          <div>
            {/* <button onClick={() => {window.open("https://supplier-backend.azupin.glass/pdf/" + data["_id"], "_blank")}} className="text-white border rounded-full px-6 py-2 hover:bg-white duration-300 hover:text-black flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
              </svg>
              Print / Download
            </button> */}
          </div>

          <div className="flex">
            <button onClick={() => save()} className="text-white border border-green-600 rounded-full px-6 py-2 bg-green-600">Save & Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenOnlineOrder