import axios from 'axios'
import { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar';
import LoadingIcon from '../components/LoadingIcon';
import moment from 'moment';

function ReportPage() {

  const [ data, setData ] = useState([]);
  const [ asd, setURL] = useState("");
  const [ loading, setLoading ] = useState(true);
  const [ scanLoading, setScanLoading ] = useState(false);
  const [ scanned, setScanned ] = useState();
  const [ loadedInvoice, setLoadedInvoice ] = useState(false);
  const [ month, setMonth ] = useState(moment().month());
  const [ year, setYear ] = useState(moment().year());

  const formatDates = (da) => {

    let dates = [];

    const CURRENT_MONTH = moment().month(); // int

    const arr = da.filter(e => moment(e["timestamp"]).year() === moment().year())

    // POPULATE ARRAY;

    for(let i = 0; i <= CURRENT_MONTH ; i++){
      dates.push({month: i, count: 0});
    };

    arr.forEach(x => {
      const int = Number(new Date(x["timestamp"]).getMonth())
      dates[int]["count"] += x["salePrice"]
    })

    return dates;
  }

  const getTotalDue = (arr) => {

    let total = 0;

    arr.forEach(x => {
      total += x["salePrice"] - x["deposit"]
    })

    return total.toFixed(2);

  }

  function organizeOrdersByDay(orders = data["orders"]) {
    // Create an array of 31 elements, all initialized to 0
    // This covers all days in any month (1-31)
    const ordersByDay = new Array(moment().daysInMonth()).fill(0);

    orders.forEach(order => {
        // Extract the day from the timestamp
        const day = new Date(order.timestamp).getDate();

        // Increment the count for this day
        // Subtract 1 from the day to match the array index (0-based)
        ordersByDay[day - 1]++;
    });

    return ordersByDay;
  }

  function getDaysArrayByMonth() {
    var daysInMonth = moment().daysInMonth();
    var arrDays = [];
  
    while(daysInMonth) {
      var current = moment().date(daysInMonth);
      arrDays.push(String(moment(current).format('ddd D')))
      daysInMonth--;
    }
  
    return arrDays.reverse();
  }

  const fetchData = async () => {

    const res = await axios.get("https://backend.azupin.glass/monthly-report?month=" + month + "&year=" + year);

    setData(res["data"])

    setLoading(false)

  };
  
  const swapMonth = async (wantedMonth) => {

    const value = wantedMonth.target.value;
    const yr = value.split("-")[0];
    const mt = value.split("-")[1];

    setMonth(Number(mt - 1));
    setYear(Number(yr));

    setLoading(true)

  }

  
  useEffect(() => {

    fetchData();

  }, [setMonth, month, setYear, year])

  useEffect(() => {

    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = "Bearer " + token;
      
      return config;
    });

    // setLoading(false)
    fetchData();

    // eslint-disable-next-line
  }, [])

  return (
    <div className="lg:flex">

      <Navbar />

      <div className="w-full h-screen overflow-y-scroll bg-white">
        {
          loading
          ?
          <LoadingIcon />
          :
          <div className="mx-auto">
            
            <div className="flex justify-between items-center px-4 py-2">
              <h4 className="text-3xl font-bold ">Reports</h4>

              <input onChange={swapMonth} className="border shadow p-2" type="month" value={`${year}-${(month + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}`} />
            </div>

            <div className="flex justify-start items-center gap-4 h-72 px-4 py-2">
              {/* this month's sales total qty */}
              <div className="flex flex-col shadow border h-full bg-white w-96 justify-center items-center ">
                <div className="-z-0 flex flex-col text-center justify-center items-center">
                  <h4 className="text-2xl font-semibold">
                    This Month's Sales
                  </h4>
                  <p className="text-4xl">
                    {data["Msales"]}
                  </p>
                </div>
              </div>
              {/* this month's sale sum total */}
              <div className="flex flex-col shadow border h-full bg-white w-96 justify-center items-center ">
                <div className="-z-0 flex flex-col text-center justify-center items-center">
                  <h4 className="text-2xl font-semibold">
                    This Month's Revenue
                  </h4>
                  <p className="text-4xl">
                    ${data["Mrev"]}
                  </p>
                </div>
              </div>
              {/* this month's new customers */}
              <div className="flex flex-col shadow border h-full bg-white w-96 justify-center items-center ">
                <div className="-z-0 flex flex-col text-center justify-center items-center">
                  <h4 className="text-2xl font-semibold">
                    Month's New Customers
                  </h4>
                  <p className="text-4xl">
                    {data["Mcustomers"]}
                  </p>
                </div>
              </div>
              {/* best customer this month */}
              <div className="flex flex-col shadow border h-full bg-white w-96 justify-center items-center ">
                <div className="-z-0 flex flex-col text-center justify-center items-center">
                  <h4 className="text-2xl font-semibold">
                    Month's Best Customer
                  </h4>
                  <p className="text-xl">
                    <span className="font-bold">{data["bestCustomer"]}</span> : ${data["customerSpent"]}
                  </p>
                </div>
              </div>
              {/* total due */}
              <div className="flex flex-col shadow border h-full bg-white w-96 justify-center items-center ">
                <div className="-z-0 flex flex-col text-center justify-center items-center">
                  <h4 className="text-2xl font-semibold">
                    Due this Month
                  </h4>
                  <p className="text-3xl text-red-600 font-bold">
                    ${getTotalDue(data["orders"].filter(x => x["salePrice"].toFixed(2) - x["deposit"].toFixed(2) > 0 ))}
                  </p>
                </div>
              </div>
            </div>

            <div className="text-[0.9rem] border-collapse flex font-semibold mt-4">
              <p className="border p-[0.05rem] w-16">Date</p>
              <p className="border p-[0.05rem] w-16">Order</p>
              <p className="border p-[0.05rem] w-52 truncate">Customer</p>
              <p className="border p-[0.05rem] w-8 text-center">Qty</p>
              <p className="border p-[0.05rem] w-20 text-center">Sale</p>
              <p className="border p-[0.05rem] w-20 text-center">Paid</p>
              <p className="border p-[0.05rem] w-20 text-center">Balance</p>
              <p className="border p-[0.05rem] w-20 text-center">Sales</p>
              <p className="border p-[0.05rem] w-40 text-center">Installation</p>
              {/* paid ? */}
              {/* payment method */}
              <h3 className="border p-[0.05rem] w-20 text-center">Status</h3>
              <p className="truncate flex-1 border p-[0.05rem]">Message</p>
            </div>
            <div>
              {data["orders"].map(x => (
                <div key={x["_id"]} className="text-[0.9rem] border-collapse flex">
                  <p className="border p-[0.05rem] w-16">{moment(x["orderDate"]).format("DD/MM")}</p>
                  <p className="border p-[0.05rem] w-16">{x["order"]}</p>
                  <p className="border p-[0.05rem] w-52 truncate">{x["customer"]}</p>
                  <p className="border p-[0.05rem] w-8 text-center">{x["products"].length}</p>
                  <p className="border p-[0.05rem] w-20 text-center">${x["salePrice"].toFixed(2)}</p>
                  <p className="border p-[0.05rem] w-20 text-center">${x["deposit"].toFixed(2)}</p>
                  <p className={`border p-[0.05rem] w-20 text-center ${x["salePrice"] <= x["deposit"] ? "invisible" : "text-red-600 font-semibold"} `}>${x["salePrice"] <= x["deposit"] ? 0 : (x["salePrice"].toFixed(2) - x["deposit"].toFixed(2)).toFixed(2)}</p>
                  <p className="border p-[0.05rem] w-20 text-center">{x["activities"][0].split(" ")[0]}</p>
                  <p className="border p-[0.05rem] w-40 text-center">Installation</p>
                  {/* paid ? */}
                  {/* payment method */}
                  <h3 className="font-bold border p-[0.05rem] w-20 text-center">
                    {
                      x["products"].filter(a => a["dispatched"] === "dispatched" || a["dispatched"] === true).length === x["products"].length
                      ?
                      "✅"
                      :
                      x["products"].filter(a => a["dispatched"] === "dispatched" || a["dispatched"] === true).length + " / " + x["products"].length
                    }
                  </h3>
                  <p className="truncate flex-1 border p-[0.05rem]">{x["message"]}</p>
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default ReportPage;
