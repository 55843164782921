import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import FilePreview from './FilePreview';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';
import moment from 'moment';
import LoadingIcon from './LoadingIcon';

function OpenClient({fetchCustomers, EDITING_CLIENT_ID, set_EDITING_CLIENT_ID}) {
  
  const [ data, setData ] = useState();
  const [loading, setLoading] = useState(true);
  const [ active, setActive ] = useState(true);
  const [showFile, setShowFile] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const res = await axios.post("https://backend.azupin.glass/customer/edit", data);

    if(res){
      setLoading(false);
      setActive(false);
      setTimeout(() => {
        fetchCustomers();
        set_EDITING_CLIENT_ID(false);
      }, 500);
    }
  }

  const getFiles = (e) => {
    setData({...data, files: [...data["files"], ...e]})
  }

  const deleteFile = (t) => {
    setData({...data, files: data["files"].filter(x => x["name"] !== t)})
  }

  const sendEmail = async (e) => {
    const obj = {
      id: data["_id"]
    };

    console.log("s")
    const result = await axios.post("https://backend.azupin.glass/customer/email", obj);

    if(result){
      alert("Email has been sent...");

      setTimeout(() => {
        
        FETCH_CUSTOMER()

      }, 1000);
    }
  }

  const FETCH_CUSTOMER = async (e) => {
    const FETCHED_RESULT = await axios.get("https://backend.azupin.glass/customer/" + EDITING_CLIENT_ID);
    const FETCHED_INVOICES = await axios.get("https://backend.azupin.glass/invoices/" + FETCHED_RESULT["data"]["title"]);
    setData({...FETCHED_RESULT["data"], invoices: [...FETCHED_INVOICES["data"].map(x => {return {...x, timestamp: moment(x["timestamp"]).format("L")}})]});
    setLoading(false);

  }

  useEffect(() => {

    FETCH_CUSTOMER();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="z-10 fixed top-0 right-0 h-screen w-screen">
        {
          showFile
          ?
          <FilePreview url={showFile} setShowFile={setShowFile} />
          :
          ""
        }
      <div className={`${active ? "slide-in-right" : "slide-out-right"} bg-white w-[40vw] h-full p-10 z-[5] absolute top-0 right-0 overflow-y-scroll`}>

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"  onClick={() => {setActive(false); setTimeout(() => {set_EDITING_CLIENT_ID(false)}, 500)}}  className="hover:text-gray-500 duration-300 cursor-pointer w-10 h-10 top-5 right-5 absolute">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>


        <h3 className="text-xl font-bold uppercase mb-4">You're editing - {data ? data["title"] : ""}</h3>
        {
          !loading
          ?
          <form onSubmit={handleSubmit} className="flex flex-col">
            {
              <VictoryChart domainPadding={40}>
                <VictoryAxis tickFormat={(y) => (`${moment(y).format("DD-MM")}`)} />
                <VictoryAxis dependentAxis tickFormat={(x) => (`$${x / 1000}k`)} />
                <VictoryBar data={data["invoices"]} x="timestamp" y="salePrice" />
              </VictoryChart>
            }
            
            <div className="w-full flex mb-2">
              <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
                <label for="title" className="text-xs">Title</label>
                <input onChange={(e) => setData({...data, title: e.target.value})} value={data["title"]} className="px-2 py-1 outline-none border w-full" placeholder='Title' name="title" />
              </div>
              <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
                <label for="title" className="text-xs">Handler</label>
                <input onChange={(e) => setData({...data, handler: e.target.value})} value={data["handler"]} className="px-2 py-1 outline-none border w-full" placeholder='Handler' name="title" />
              </div>
              <div className="flex flex-col justify-start items-start">
                <label for="level" className="text-xs">Level</label>
                <select onChange={(e) => setData({...data, customerType: e.target.value})} value={data["customerType"]} className="px-4 py-2 border outline-none h-[2.1rem]" name="level">
                  <option value={"contractorPricing"}>Contractor</option>
                  <option value={"installerPricing"}>Installer</option>
                  <option value={"shopPricing"}>Shop</option>
                </select>
              </div>
            </div>
            <div className="w-full flex mb-2">
              <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
                <label for="title" className="text-xs">Phone</label>
                <input type="tel" onChange={(e) => setData({...data, phoneNumber: e.target.value})} value={data["phoneNumber"]} className="px-2 py-1 outline-none border w-full" placeholder='Phone' name="phoneNumber" />
              </div>
              <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
                <label for="title" className="text-xs">Email</label>
                <input type="email" onChange={(e) => setData({...data, email: e.target.value})} value={data["email"]} className="px-2 py-1 mr-2 outline-none border w-full" placeholder='Email' name="email" />
              </div>
              <div className="flex flex-1 w-full flex-col justify-start items-start">
                <label for="due" className="text-xs">Balance</label>
                <input onChange={(e) => setData({...data, due: e.target.value})} value={data["due"]} className="px-2 py-1 outline-none border w-full" placeholder='Balance' name="due" type="float" />
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-full mb-2 mr-2">
              <label for="billingAddress" className="text-xs">billingAddress</label>
              <textarea onChange={(e) => setData({...data, billingAddress: e.target.value})} value={data["billingAddress"]} required className="px-2 py-1 outline-none w-full border" placeholder='billingAddress' name="billingAddress" />
            </div>
            <div className="flex flex-col justify-start items-start w-full mb-2">
              <label for="message" className="text-xs">Message</label>
              <input onChange={(e) => setData({...data, message: e.target.value})} value={data["message"]} className="px-2 py-1 outline-none border w-full" placeholder='Message' name="message" />
            </div>

            <div className="my-4">
              <hr/>
            </div>

            <div className="flex flex-col justify-start items-start w-full mb-2">
              <label for="resale" className="text-xs">Resale</label>
              <input onChange={(e) => setData({...data, resale: e.target.value})} value={data["resale"]} className="px-2 py-1 outline-none border w-full" placeholder="Resale" name="resale" />
            </div>

            <div className="my-4">
              <hr/>
            </div>

            <div className="mb-6">
              <h4 className="text-xs">Files</h4>
              <div>
                <div className="px-4 py-2 border flex justify-center items-center ">
                  <FileBase64
                    multiple={ true }
                    onDone={ getFiles } 
                  />
                </div>

                <div className="grid lg:grid-cols-2 gap-2 my-4">
                  {
                    data["files"].map((g, index) => (
                      <div className="relative">
                        <div onClick={() => window.confirm("Are you sure you want to delete this file: " + g["name"]) ? deleteFile(g["name"]) : ""} className="absolute -top-2 -right-2 h-7 w-7 cursor-pointer bg-red-500 rounded-full flex justify-center items-center shadow hover:rotate-180 duration-500">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-white w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                        <div onClick={() => setShowFile(g["base64"])} key={index} className="border shadow rounded p-2 cursor-pointer hover:shadow-xl duration-300">
                          <h5 className="truncate text-xl font-semibold">{g["name"]}</h5>
                          <p>{g["size"]}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            <button className="px-4 py-2 bg-blue-400">Submit</button>
            <button type="button" onClick={() => sendEmail()} className="px-4 py-2 bg-blue-400 mt-2">Send Confirmation</button>

            <h3>Confirmation Sent: {data["emailSent"] ? "✅" : "❌"}</h3>

          </form>
          :
          <LoadingIcon />
        }

      </div>
      <div onClick={() => {setActive(false); setTimeout(() => {set_EDITING_CLIENT_ID(false)}, 500)}} className="bg-black w-full h-full bg-opacity-50 absolute top-0 cursor-pointer left-0"></div>
    </div>
  )
}

export default OpenClient