import React, {useState, useEffect} from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios';
import moment from 'moment/moment';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
// import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import CreateInstall from '../components/CreateInstall';
import OpenInstall from '../components/OpenInstall';
import multiMonthPlugin from '@fullcalendar/multimonth'


function Install() {

  const [ create, setCreate ] = useState(false);

  const [ openthis, setOpenThis ] = useState("");

  const fetchData = async () => {
    const DATES_DATA = await axios.get("https://backend.azupin.glass/dates");

    setDates(DATES_DATA["data"].map(x => ({...x, id: x["_id"], title: x["customerName"], start: x["date"], end: String(moment(x["end"], "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")), className: `${x["complete"] ? "bg-green-500 border-green-600" : "border-red-700 bg-red-600"} px-2 py-1` })));
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [ dates, setDates ] = useState([
  ])

  const changeChangeDate = (e) => {

    const ind = dates.findIndex(a => a["title"] === e["title"]);
    
    dates[ind] = e;
  
    setDates(dates);

  }


  const openDialogue = (s) => {
    setOpenThis(s.event.id)
  }

  return (
    <div className="lg:flex">

      <Navbar />

      {
        openthis
        ?
        <OpenInstall fetchData={fetchData} createInvoice={openthis}  setCreateInvoice={setOpenThis} />
        :
        ""
      }

      {
        create
        ?
        <CreateInstall fetchData={fetchData}  setCreateInvoice={setCreate} />
        :
        ""
      }

      <div className="overflow-y-scroll h-screen w-full p-10">
        <div className="flex justify-between items-center pb-10">
          <h3 className="font-bold text-3xl">Installations</h3>
          <button onClick={() => setCreate(!create)} className="bg-blue-500 font-bold text-white px-4 py-2 rounded shadow-lg hover:scale-110 transform duration-300">Add</button>
        </div>
      <section className="text-gray-600 body-font">
  {/* <div className="container pb-10 mx-auto">
    <div className="flex flex-wrap -m-4 text-center">
      <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
            <path d="M8 17l4 4 4-4m-4-5v9"></path>
            <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
          </svg>
          <h2 className="title-font font-medium text-3xl text-gray-900">{dates.length}</h2>
          <p className="leading-relaxed">Installations</p>
        </div>
      </div>
      <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
            <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
          </svg>
          <h2 className="title-font font-medium text-3xl text-gray-900">{dates.filter(x => x["complete"] === true).length}</h2>
          <p className="leading-relaxed">Done</p>
        </div>
      </div>
      <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
            <path d="M3 18v-6a9 9 0 0118 0v6"></path>
            <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
          </svg>
          <h2 className="title-font font-medium text-3xl text-gray-900">{dates.filter(x => x["ready"] === true && x["done"] === false).length}</h2>
          <p className="leading-relaxed">Ready</p>
        </div>
      </div>
      <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>
          <h2 className="title-font font-medium text-3xl text-gray-900">{dates.filter(x => x["complete"] === false).length}</h2>
          <p className="leading-relaxed">Not Done</p>
        </div>
      </div>
    </div>
  </div> */}
</section>
        
      <div className="flex flex-row justify-between items-start">
        <div className="px-4 py-2 flex-1">
          <FullCalendar
            plugins={[ dayGridPlugin, multiMonthPlugin ]}
            views={{Year: { type: "multiMonth", duration: { months: 12}}, Month: { type: "dayGridMonth"}}}
            headerToolbar={{right: 'prev Month Year next',}}
            initialView="Month"
            dayMaxEventRows={8}
            expandRows={false}
            defaultAllDay={true}
            contentHeight={1000}
            aspectRatio={1}
            eventClick={openDialogue}
            editable={true}
            events={dates}
            nextDayThreshold={'T23:59:00'}
            eventChange={d => changeChangeDate({title: d["event"]["title"], start: d["event"]["startStr"], end: d["event"]["endStr"], src: d})}
          />
        </div>
      </div>

      </div>
    </div>
  )
}

export default Install