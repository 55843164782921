import axios from 'axios'
import { useState, useEffect } from 'react';
import MainPage from './pages/MainPage';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Glass from './pages/Glass';
import Inventory from './pages/Inventory';
import Invoice from './pages/Invoice';
import Customer from './pages/Customer';
import Product from './pages/Product';
import Login from './pages/Login';
import Estimate from './pages/Estimate';
import Online from './pages/Online';
import Install from './pages/Install';
import Return from './pages/Return';
import ReportPage from './pages/Report';

function App() {

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/glass" element={<Glass />} />
        <Route exact path="/online" element={<Online />} />
        <Route exact path="/inventory" element={<Inventory />} />
        <Route exact path="/invoice" element={<Invoice />} />
        <Route exact path="/customer" element={<Customer />} />
        <Route exact path="/install" element={<Install />} />
        <Route exact path="/product" element={<Product />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/estimate" element={<Estimate />} />
        <Route exact path="/return" element={<Return />} />
        <Route exact path="/report" element={<ReportPage />} />
      </Routes>
    </Router>
  );
}

export default App;
