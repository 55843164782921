import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios';
import Textarea from 'react-expanding-textarea'
import Select from 'react-select'
import moment from 'moment';
import FileBase64 from 'react-file-base64'
import FilePreview from './FilePreview';

function OpenInstall({fetchData, createInvoice, setCreateInvoice}) { 

  const [ active, setActive ] = useState(true);
  const [showFile, setShowFile] = useState("");
  const [ data, setData ] = useState({
    ready: false,
    complete: false,
    productInformation: [
      {
        title: "",
        description: "",
        quantity: 1,
        images: []
      }
    ],
  });

  async function reduce_image_file_size(base64Str, MAX_WIDTH = 400, MAX_HEIGHT = 400) {
    let resized_base64 = await new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
            let canvas = document.createElement('canvas')
            let width = img.width
            let height = img.height

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width
                    width = MAX_WIDTH
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height
                    height = MAX_HEIGHT
                }
            }
            canvas.width = width
            canvas.height = height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL()) // this will return base64 image results after resize
        }
    });
    return {base64: resized_base64, size: (Math.round(resized_base64.length * 3/4) / 1024).toFixed(2) };
}

  const deleteDate = async () => {

    if(window.confirm("Are you sure you want to delete this installation?")){
      const RESPONSE = await axios.delete("https://backend.azupin.glass/deleteDate/" + createInvoice);
  
      if(RESPONSE){
  
        setTimeout(() => {
          setLoading(false);
          fetchData();
          setActive(false);
          setTimeout(() => {
            setCreateInvoice(false);
          }, 500);
        }, 1000);
  
      }
    }


  }

  const getFiles = async (e, index) => {
    let copy = data;

    const compressed = await Promise.all(e.map(async (x) => 
    {
      const reduced = x["type"].includes("image") ? await reduce_image_file_size(x["base64"]) : null;
      return {
        ...x,
        base64: x["type"].includes("image") ? reduced["base64"] : x["base64"],
        size: x["type"].includes("image") ? reduced["size"] + " Kb" : x["size"]
      }
    }
    ))

    console.log(compressed)

    // console.log(copy["productInformation"][index])

    if(copy["productInformation"][index]["images"]){
      copy["productInformation"][index]["images"] = [...data["productInformation"][index]["images"] ,...compressed];
    }else{
      copy["productInformation"][index]["images"] = compressed;
    }


    setData({...data, productInformation: copy["productInformation"]})

  }

  const save = async () => {

    setLoading(true)

    const res = await axios.post("https://backend.azupin.glass/updateDate/", {...data, productInformation: data["productInformation"]});

    if(res){
      setTimeout(() => {
        setLoading(false);
        fetchData();
        setActive(false);
        setTimeout(() => {
          setCreateInvoice(false);
        }, 500);
      }, 1000);
    }
  }

  const deleteFile = (t, index) => {

    let copy = data;

    console.log(t, index)

    copy["productInformation"][index]["images"] = data["productInformation"][index]["images"].filter(x => x["name"] !== t)

    setData({...data, productInformation: copy["productInformation"]})
  }

  const deleteRow = (index) => {

    setData({...data, productInformation: data["productInformation"].filter((x, i) => i !== index)})   

  }

  const addProduct = () => {
    setData({...data, productInformation: [...data["productInformation"], {
        title: "", 
        description: "", 
        quantity: 1, 
        images: []
      }]
    })
  }
  
  const editQuantity = (val, index) => {
    let copy = data;

    copy["productInformation"][index]["quantity"] = Number(val);

    setData({...data, productInformation: copy["productInformation"]})

  }

  const editTitle = (val, index) => {
    let copy = data;

    console.log(val)

    copy["productInformation"][index]["title"] = val;

    setData({...data, productInformation: copy["productInformation"]})

  }

  const changeDescription = (val, index) => {
    let copy = data;

    copy["productInformation"][index]["description"] = val;
    
    setData({...data, productInformation: copy["productInformation"]})
  }

  const [ products, setProduct ] = useState([]);

  const [ loading, setLoading ] = useState(true);


  const fetchInstall = async () => {

    const RES = await axios.get("https://backend.azupin.glass/date/" + createInvoice);

    setData({...RES["data"]})

    setLoading(false)

  };

  useEffect(() => {
    fetchInstall()
  }, [])

  return (
    <div className={`${active ? "slide-in-top" : "slide-out-top"} fixed top-0 left-0 z-10`}>
        {
          showFile
          ?
          <FilePreview url={showFile} setShowFile={setShowFile} />
          :
          ""
        }
      <div className='bg-white overflow-y-scroll relative flex justify-center items-center w-screen h-screen top-0 left-0'>
        <div className="flex justify-between items-center absolute top-0 left-0 py-2 px-4 w-full">
          <div className="flex justify-start items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
            <h3 className="font-bold text-xl">
              Install #{data["invoiceNumber"]}
            </h3>
          </div>
          <svg onClick={() => {setActive(false); setTimeout(() => {setCreateInvoice(false)}, 500)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-10 h-10 hover:text-red-500 duration-300">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        {
          loading
          ?
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 animate-spin">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
          :
          <div className="w-full h-full">
            <div className="px-4 pt-14 pb-4 bg-gray-100 flex flex-row justify-between">

              <div className="flex flex-col justify-start items-start">

                <div className="flex flex-row justify-start items-start">
                  <div className="flex flex-col m-1">
                    <label className="">Customer</label>
                    <input onChange={(e) => setData({...data, customerName: e.target.value})} value={data["customerName"]} className="px-2 py-1 outline-none border" />
                  </div>
                  <div className="flex flex-col m-1">
                    <label className="">Phone</label>
                    <input onChange={(e) => setData({...data, phoneNumber: e.target.value})} value={data["phoneNumber"]} className="px-2 py-1 outline-none border" />
                  </div>
                </div>

                <div className="flex flex-col w-full px-1">
                  <label className="">Address</label>
                  <textarea onChange={(e) => setData({...data, address: e.target.value})} value={data["address"]} className="px-2 py-1 outline-none border"></textarea>
                </div>

              </div>

              <div>
                <div className="flex flex-col m-1">
                  <label className="">Installer</label>
                  <input onChange={(e) => setData({...data, installer: e.target.value})} value={data["installer"]} className="px-2 py-1 outline-none border" />
                </div>
                <div className="flex flex-row mx-1 items-center">
                  <label className="">Ready</label>
                  <div onClick={() => setData({...data, ready: !data["ready"]})} className={`${!data["ready"] ? "bg-white" : "bg-green-500"} px-3 py-3 border bg-white ml-2`}></div>
                </div>
                <div className="flex flex-row mx-1 items-center">
                  <label className="">Complete</label>
                  <div onClick={() => setData({...data, complete: !data["complete"]})} className={`${!data["complete"] ? "bg-white" : "bg-green-500"} px-3 py-3 border bg-white ml-2`}></div>
                </div>
              </div>

              <div className="flex flex-col justify-start items-start">
            
                  <div className="flex flex-col mx-1">
                    <label className="">Start</label>
                    <input value={data["date"]} onChange={(e) => setData({...data, date: String(e.target.value)})} type="date" className="px-2 py-1 outline-none border" />
                  </div>
                  <div className="flex flex-col mx-1">
                    <label className="">End</label>
                    <input value={data["end"]} onChange={(e) => setData({...data, end: String(e.target.value)})} type="date" className="px-2 py-1 outline-none border" />
                  </div>

              </div>

            </div>

            <div className="bg-white px-4 py-2">

            <div className="flex flex-col w-full">
              <div className="overflow-x-auto">
                <div className="py-2 inline-block min-w-full">
                  <div className="overflow-hidden">
                    <table className="min-w-full border-collapse">
                      <thead className="bg-white border-b">
                        <tr>
                          <th scope="col" className="border-b border-r text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            #
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-2 text-left">
                            Title
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Description
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left w-96">
                            Image/s
                          </th>
                          <th scope="col" className="border-b border-r border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Qty
                          </th>
                          <th scope="col" className="w-10 border-b border-l text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data["productInformation"].map((x, index) => (
                            <tr key={x["barcode"]} className={`hover:shadow-lg duration-300 border-b ${!Boolean(index % 2) ? "bg-white" : "bg-white"}`}>
                              <td className="border-t border-b border-r px-6 w-10 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                                {index + 1}
                              </td>
                              <td className="border relative px-6 w-96 py-2 whitespace-nowrap text-sm text-gray-900">
                                <input value={x["title"]} placeholder="Title" onChange={(s) => editTitle(s.target.value, index)} className="p-2 outline-none"  />
                              </td>
                              <td className="border text-gray-900 px-6 py-4 whitespace-nowrap">
                                <Textarea placeholder='Description' value={x["description"]} onChange={(e) => changeDescription(e.target.value, index)} className="w-full text-xs h-auto outline-none" />
                              </td>
                              <td className="border text-gray-900 px-6 py-4 whitespace-nowrap">
                                <FileBase64
                                  className="block w-full px-3 py-2 mt-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-4 file:py-1 file:border-none hover:border-dashed cursor-pointer file:rounded-full placeholder-gray-400/70  focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                                  multiple={ true }
                                  onDone={ (s) => getFiles(s, index) } 
                                />
                                <div className="flex flex-wrap flex-row">
                                  {x["images"] ? x["images"].map((d, index) => (<div key={index} className="border shadow rounded p-1 cursor-pointer hover:shadow-xl duration-300 relative">
                                    <div onClick={() => window.confirm("Are you sure you want to delete this file: " + d["name"]) ? deleteFile(d["name"], index) : ""} className="absolute -top-2 -right-2 h-4 w-4 cursor-pointer bg-red-500 rounded-full flex justify-center items-center shadow hover:rotate-180 duration-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-white w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                      </svg>
                                    </div>
                                    <div onClick={() => setShowFile(d["base64"])}>
                                      <h5 className="truncate text-xs font-semibold">{d["name"]}</h5>
                                      <p className="text-xs">{d["size"]}</p>
                                    </div>
                                  </div>)) : ""}
                                </div>
                              </td>
                              <td className="border text-lg w-20 text-gray-900 px-6 py-4 whitespace-nowrap">
                                <input min={0} onChange={(e) => editQuantity(e.target.value, index)} value={x["quantity"]} className="w-20 outline-none border p-1" placeholder="Qty" type="number"  />
                              </td>
                              <td className="border text-sm text-gray-900 font-bold px-6 py-4 whitespace-nowrap">
                                <svg onClick={() => deleteRow(index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:text-red-900 duration-300 cursor-pointer mx-auto ">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                  <div className="flex flex-row">
                    <button onClick={() => addProduct()} className="px-4 py-2 border shadow rounded-lg border-black text-xs mr-2">Add Line</button>

                  </div>
                </div>
              <div className="flex justify-between items-start">
                <div>
                  <div className="mt-4">
                    <h3>Message on Install...</h3>
                    <textarea onChange={(e) => setData({...data, notes: e.target.value})} defaultValue={data["notes"]} className="px-4 py-2 border outline-none w-96 h-40"></textarea>
                  </div>
                </div>
              </div>
            </div>

            </div>
          </div>
        }

        {/* footer */}
        <div className="bg-gray-500 py-2 px-4 fixed text-sm bottom-0 left-0 w-full h-30 flex justify-between items-center">
          <div className="flex justify-center items-center">
            <button onClick={() => {setActive(false); setTimeout(() => {setCreateInvoice(false)}, 500)}} className="text-white border rounded-full px-6 py-2 hover:bg-white duration-300 hover:text-black">Cancel</button>
            <button className="text-white border rounded-full px-6 py-2 ml-2 hover:bg-white duration-300 hover:text-black">Clear</button>
            <button onClick={() => deleteDate()} className="text-white border bg-red-500 rounded-full px-6 py-2 ml-2 hover:bg-white duration-300 hover:text-black">Delete</button>
          </div>

          <div className="flex">
            <button onClick={() => save()} className="text-white border border-green-600 rounded-full px-6 py-2 bg-green-600">Save and close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenInstall