import React, { useState } from 'react';
import axios from 'axios';

function OpenProduct({fetchProducts, createProduct, setCreateProduct}) {

  const [ active, setActive ] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const obj = {
      title: e.target.title.value,
      description: e.target.description.value,
      price: Number(e.target.price.value),
      image: e.target.image.value,
      SKU: e.target.SKU.value
    };

    const res = await axios.post("https://backend.azupin.glass/product/", obj);

    if(res){
      setActive(false); 
      setTimeout(() => {
        setCreateProduct(false);
      }, 500)
      setTimeout(() => {
        fetchProducts();
      }, 1000);
    }
  }

  return (
    <div className={`z-10 fixed top-0 right-0 h-screen w-screen`}>
      <div className={`${active ? "slide-in-right" : "slide-out-right"} bg-white w-1/2 h-full p-10 z-[5] absolute top-0 right-0`}>

        <form onSubmit={handleSubmit} className="flex flex-col">
          
          <h3 className="text-xl font-bold uppercase mb-4">Create a Product</h3>
          <div className="w-full flex mb-2">
            <div className="flex flex-1 w-full mr-2 flex-col justify-start items-start">
              <label for="title" className="text-xs">Title</label>
              <input required className="w-full px-2 py-1 outline-none border" placeholder='Title' id="title" name="title" />
            </div>
            <div className="flex flex-col justify-start items-start">
              <label for="price" className="text-xs">Price</label>
              <input required className="flex-1 px-2 py-1 outline-none border" placeholder='Price' name="price" id="price" type="float" />
            </div>
          </div>
          <div className="flex flex-col justify-start items-start w-full mb-2">
            <label for="description" className="text-xs">Description</label>
            <textarea required className="px-2 py-1 outline-none w-full border" placeholder='Description' name="description" />
          </div>
          <div className="flex">
            <div className="mr-2 flex flex-col justify-start items-start w-full mb-2">
              <label for="image" className="text-xs">Image</label>
              <input className="px-2 py-1 outline-none border w-full" placeholder='Image' name="image" />
            </div>
            <div className="flex flex-col justify-start items-start w-full mb-2">
              <label for="SKU" className="text-xs">SKU</label>
              <input className="px-2 py-1 outline-none border w-full" placeholder='SKU' name="SKU" />
            </div>
          </div>

          <button className="px-4 py-2 bg-blue-400">Submit</button>

        </form>

      </div>
      <div onClick={() => {setActive(false); setTimeout(() => {setCreateProduct(false)}, 500)}} className="bg-black w-full h-full bg-opacity-50 absolute top-0 cursor-pointer left-0"></div>
    </div>
  )
}

export default OpenProduct