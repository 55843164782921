import React from 'react'

function FilePreview({setShowFile, url}) {
  return (
    <div onClick={() => setShowFile("")} className="fixed w-screen z-20 h-screen flex justify-center cursor-pointer items-center bg-black bg-opacity-50">
      <div className="p-2 bg-white shadow border rounded w-[50vw] h-[90vh]">
        {
          url.includes("image")
          ?
          <img src={url} className="h-full  mx-auto object-contain" />
          :
          <iframe src={url} className="w-full h-full mx-auto"></iframe>
        }
      </div>
    </div>
  )
}

export default FilePreview